import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { Bar, ComposedChart, XAxis, YAxis, Line, Tooltip, ResponsiveContainer, CartesianGrid, AxisDomain } from 'recharts';
import { formatXAxis, utcFromUnixTime, formatMetricCompact } from '../../lib/formatters';
import { Ticker } from '../../lib/tickers';
import { ChartTooltip } from './ChartTooltip';
import styles from './Chart.module.css';
import { CPD_METRICS, Metric, allMetrics, MetricFormat, TimeZoneDecriptor } from '../../lib/types';

export interface BiaxialProps {
    readonly items: any[];
    readonly leftLine: Metric;
    readonly rightLine: Metric;
    readonly groupBy?: Ticker;
    readonly timeZone: TimeZoneDecriptor;
}

const leftColor = '#2E8CB8';
const leftAxisColor = '#2E8CB8';
const rightColor = '#DD7703';
const rightAxisColor = '#DD7703';
const tickColor = '#767676';
const strokeColor = '#ececec';

export function yAxisDomain(metric: Metric): [AxisDomain, AxisDomain] {
    const metricFormat = allMetrics.find(m => m.field === metric)?.format ?? MetricFormat.COUNT;
    const defaultMax = metricFormat === MetricFormat.COUNT ? 5 : 0.01;
    return [0, (dataMax: number) => (dataMax ? dataMax : defaultMax)];
}

export const formatTooltipJumbo = (label: string | number, groupBy: Ticker | undefined, timeZone: TimeZoneDecriptor) => {
    try {
        if (groupBy === Ticker.hours) {
            return utcFromUnixTime(+label).toFormat('MMMM d, HH:mm') + ' ' + timeZone.shortLabel;
        } else {
            return utcFromUnixTime(+label).toFormat('MMMM d, yyyy');
        }
    } catch (e) {
        console.warn('cannot format Tooltip, epochSec:', label);
        return '';
    }
};

export const Biaxial: React.FC<BiaxialProps> = ({ items, leftLine, rightLine, groupBy, timeZone }) => {
    const leftDataKey = useCallback((dataPoint: any): number => dataPoint[leftLine] ?? 0, [leftLine]);

    const rightDataKey = useCallback((dataPoint: any): number => dataPoint[rightLine] ?? 0, [rightLine]);

    const formatXAxisGroupBy = useCallback(
        (x: number): string => {
            return formatXAxis(x, groupBy);
        },
        [groupBy],
    );

    const formatLeftYAxis = useCallback((y: any) => formatMetricCompact(y, leftLine), [leftLine]);
    const formatRightYAxis = useCallback((y: any) => formatMetricCompact(y, rightLine), [rightLine]);

    const leftYAxisDomain = useMemo(() => yAxisDomain(leftLine), [leftLine]);
    const rightYAxisDomain = useMemo(() => yAxisDomain(rightLine), [rightLine]);

    const ComposedChartTooltip = useMemo(
        () => (props: any) => {
            if (props.active && props.payload && props.payload.length) {
                return (
                    <ChartTooltip
                        label={formatTooltipJumbo(props.label, groupBy, timeZone)}
                        metrics={props.payload.map((it: any, index: number) => {
                            const metric = [leftLine, rightLine][index];
                            return {
                                metric,
                                metricName: CPD_METRICS[metric],
                                value: it.value,
                                color: it.color,
                            };
                        })}
                    />
                );
            } else {
                return null;
            }
        },
        [groupBy, leftLine, rightLine, timeZone],
    );

    const handleOnMouseEnter = useCallback((data: unknown, index: unknown, event: ChangeEvent<HTMLElement>) => {
        event.target.classList.add(styles.rectOnHover);
    }, []);

    const handleOnMouseLeave = useCallback((data: unknown, index: unknown, event: ChangeEvent<HTMLElement>) => {
        event.target.classList.remove(styles.rectOnHover);
    }, []);

    return (
        <ResponsiveContainer>
            <ComposedChart margin={{ top: 20, right: 10, bottom: 15, left: 10 }} data={items}>
                <CartesianGrid stroke={strokeColor} vertical={false} />
                <XAxis
                    dataKey="epochSec"
                    tickFormatter={formatXAxisGroupBy}
                    allowDecimals={false}
                    type="number"
                    domain={['dataMin', 'dataMax']}
                    scale="time"
                    axisLine={false}
                    tickLine={false}
                    tickMargin={10}
                    minTickGap={20}
                    padding={{ left: 35, right: 35 }}
                    stroke={tickColor}
                />
                <YAxis
                    yAxisId="left"
                    type="number"
                    scale="linear"
                    axisLine={false}
                    tickLine={false}
                    tickFormatter={formatLeftYAxis}
                    allowDecimals={false}
                    stroke={leftAxisColor}
                    tickMargin={10}
                    domain={leftYAxisDomain}
                />
                <YAxis
                    yAxisId="right"
                    orientation="right"
                    type="number"
                    scale="linear"
                    axisLine={false}
                    tickLine={false}
                    tickFormatter={formatRightYAxis}
                    allowDecimals={false}
                    stroke={rightAxisColor}
                    tickMargin={10}
                    domain={rightYAxisDomain}
                />
                <Tooltip content={<ComposedChartTooltip />} cursor={false} />
                <Bar
                    yAxisId="left"
                    maxBarSize={60}
                    dataKey={leftDataKey}
                    isAnimationActive={true}
                    animationDuration={650}
                    fill={leftColor}
                    onMouseEnter={handleOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                />
                <Line
                    yAxisId="right"
                    type="linear"
                    dataKey={rightDataKey}
                    stroke={rightColor}
                    strokeWidth={2}
                    isAnimationActive={true}
                    animationDuration={650}
                    dot={false}
                />
            </ComposedChart>
        </ResponsiveContainer>
    );
};
