export function mapPlacementType(dimensionValue?: string): string {
    switch ('' + dimensionValue) {
        case '12':
            return 'In banner';
        case '6':
            return 'Tracking only';
        case '9':
            return 'In-stream video';
        case '10':
            return 'In-stream video tracking';
        default:
            return ''; // this should not happen :)
    }
}

export function mapEnvironment(dimensionValue?: string): string {
    switch ('' + dimensionValue) {
        case '1':
            return 'In-app';
        case '2':
            return 'Web';
        default:
            return 'Not set';
    }
}

export function mapDimension(dimensionName: string, dimensionValue?: string): string {
    switch (dimensionName) {
        case 'placementType':
            return mapPlacementType(dimensionValue);
        case 'environment':
            return mapEnvironment(dimensionValue);
        default:
            return dimensionValue ?? '';
    }
}

export const verticalMappings = {
    Apparel: 'Apparel',
    Auto: 'Auto',
    B2B: 'B2B',
    Career: 'Careers',
    Corporate: 'Corporate',
    Electronics: 'Electronics',
    Entertainment: 'Entertainment',
    Financial: 'Financial',
    Gaming: 'Gaming',
    Health_Beauty: 'Health/Beauty',
    News_Media: 'News/Media',
    Medical: 'Medical',
    Consumer_Packaged_Goods: 'Consumer Packaged Goods',
    Restaurant: 'Restaurant',
    Retail: 'Retail',
    Sports: 'Sports',
    Tech_Internet: 'Tech/Internet',
    Telecom: 'Telecom',
    Travel: 'Travel',
    Services: 'Services',
    Other: 'Other',
    Government_Utilities: 'Government/Utilities',
    Insurance: 'Insurance',
};
