export interface GridState {
    hiddenMetrics: string[];
    hiddenDimensions: string[];
    cpdHiddenMetrics: string[];
    cpdHiddenDimensions: string[];
}

export const SHOW_COLUMN = 'SHOW_COLUMN';
export const HIDE_COLUMN = 'HIDE_COLUMN';

export interface ColumnActionPayloadType {
    column: string;
    isDimension: boolean;
    isCPD: boolean;
}

interface ShowColumnAction {
    type: typeof SHOW_COLUMN;
    payload: ColumnActionPayloadType;
}

interface HideColumnAction {
    type: typeof HIDE_COLUMN;
    payload: ColumnActionPayloadType;
}

export function showColumn(column: string, isDimension: boolean, isCPD: boolean = false): ColumnActionTypes {
    return {
        type: SHOW_COLUMN,
        payload: { column, isDimension, isCPD },
    };
}

export function hideColumn(column: string, isDimension: boolean, isCPD: boolean = false): ColumnActionTypes {
    return {
        type: HIDE_COLUMN,
        payload: { column, isDimension, isCPD },
    };
}

export type ColumnActionTypes = ShowColumnAction | HideColumnAction;
