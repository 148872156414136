import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styles from './Grid.module.css';
import loading from './images/loading.gif';
import { GridOptions, ColDef, GridApi, ColumnApi, GridReadyEvent, IGetRowsParams, ColumnEverythingChangedEvent } from '@ag-grid-community/core';
import { columnTypes } from './types/columnTypes';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { AgGridReact } from '@ag-grid-community/react';
import { columnTypesDef } from './types/columnTypes';
import { frameworkComponents } from './renders/cellRenders';
import { DocumentNode, useApolloClient } from '@apollo/client';
import { useDateRangeFilter, usePlacementTypesFilter } from '../../hooks/useFilters';
import { GridHeader } from './GridHeader';
import { GridPagination } from './GridPagination';
import { GridFooter } from './GridFooter';
import { useGridState } from '../../hooks/useGridColumns';

import './themes/udc-theme.css';
import './themes/ag-theme-va.css';
import { AppLoading } from '../../components/Messages/AppLoading';
import { MsgNoData } from '../../components/Messages';

export interface GridProps {
    readonly query: DocumentNode;
    readonly queryVars: Record<string, unknown>,
    readonly columnDefs: ColDef[];
    readonly dataTestId: string;
    readonly isCampaignGrid?: boolean;
}

const REQUEST_SIZE = 100;
const PAGE_SIZE = 20;

interface QueryResult {
    [key: string]: any
}

export const Grid: React.FC<GridProps> = props => {
    const [gridApi, setGridApi] = useState<GridApi>();
    const [columnApi, setColumnApi] = useState<ColumnApi>();
    const [searchQuery, setSearchQuery] = useState<string | null>(null);
    const [isReady, setReady] = useState<boolean>(false);
    const client = useApolloClient();
    const placementTypes = usePlacementTypesFilter();
    const { dateRange, timeZone } = useDateRangeFilter();
    const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
    const [totalRows, setTotalRows] = useState<number>(0);
    const grid = useGridState();

    const { columnDefs, isCampaignGrid, queryVars } = props;

    const hiddenDimensions = isCampaignGrid ? grid.cpdHiddenDimensions : grid.hiddenDimensions;
    const hiddenMetrics = isCampaignGrid ? grid.cpdHiddenMetrics : grid.hiddenMetrics;

    const extraVars = useMemo(() => {
        if (isCampaignGrid) {
            const visibleDimensions = columnDefs
                .filter(cd => cd.field && cd.type?.includes(columnTypes.isDimension) && !hiddenDimensions.includes(cd.field))
                .map(cd => cd.field);
            return {
                dimensions: visibleDimensions,
            };
        } else {
            return {};
        }
    }, [hiddenDimensions, columnDefs, isCampaignGrid]);

    useEffect(() => {
        gridApi?.setDatasource({
            async getRows(params: IGetRowsParams) {
                try {
                    let sortProperty = (params.sortModel && params.sortModel.length > 0) ? params.sortModel[0].colId : undefined;
                    let sortDirection = (params.sortModel && params.sortModel.length > 0) ? params.sortModel[0].sort.toUpperCase() : undefined;
                    const withoutDimensions = hiddenDimensions.reduce<Record<string, boolean>>((result, column) => {
                        result[`WITHOUT${column}`] = true;
                        return result;
                    }, {});
                    const res = await client.query<QueryResult>({
                        query: props.query,
                        variables: {
                            ...queryVars,
                            filterByPlacementTypes: placementTypes,
                            from: dateRange.from.toLocalTimestamp(),
                            to: dateRange.to.toLocalTimestamp(),
                            tzOffsetHours: timeZone.tzOffsetHours,
                            search: searchQuery,
                            offset: params.startRow,
                            limit: params.endRow - params.startRow,
                            sortProperty,
                            sortDirection,
                            ...withoutDimensions,
                            ...extraVars,
                        }
                    });
                    if (res.error) {
                        throw new Error(`Error querying data: ${res.error.message}`);
                    }
                    setTotalRows(res.data.meta.total);
                    setReady(true);
                    return params.successCallback(res.data.rows, res.data.meta.total);
                } catch (error) {
                    console.error((error as Error).message);
                    return params.failCallback();
                }
            },
        });
    }, [client, queryVars, props.query, gridApi, dateRange, timeZone, hiddenDimensions, placementTypes, extraVars, searchQuery, setReady]);

    const onGridReady = useCallback((event: GridReadyEvent): void => {
        setGridApi(event.api);
        setColumnApi(event.columnApi);
        // event.api.addEventListener('modelUpdated', () => {
        //     setTotalRows(event.api.paginationGetRowCount());
        // });
        event.api.addEventListener('columnEverythingChanged', (event: ColumnEverythingChangedEvent) => {
            if (event.columnApi.getAllDisplayedColumns().length < 8) {
                event.api.sizeColumnsToFit();
            } else {
                event.columnApi.autoSizeColumns(event.columnApi.getAllDisplayedColumns());
            }
        });
    }, []);

    const loadingRenderer = useCallback((params: any) => {
        if (params.value !== undefined) {
            return params.value;
        } else {
            return `<img src=${loading} alt="loading..." />`;
        }
    }, []);

    const gridOptions = useMemo<GridOptions>(
        () => ({
            rowHeight: 40,
            headerHeight: 54,
            components: {
                loadingRenderer,
                ...frameworkComponents,
                customLoadingOverlay: AppLoading,
                customNoRowsOverlay: MsgNoData,
            },
            columnTypes: columnTypesDef,
            defaultColDef: {
                // flex: 1,
                minWidth: 50,
                sortable: false,
                // resizable: true,
            },
            columnDefs: props.columnDefs,
            onGridReady,
            rowBuffer: 0,
            rowModelType: 'infinite',
            cacheBlockSize: REQUEST_SIZE,
            infiniteInitialRowCount: 1,
            paginationPageSize: PAGE_SIZE,
            cacheOverflowSize: 1,
            maxConcurrentDatasourceRequests: 2,
            maxBlocksInCache: 3,
            pagination: true,
            getRowId: row => row.data.id,
            suppressPaginationPanel: true,
            autoSizePadding: 40,
            suppressColumnVirtualisation: true,
            suppressMovableColumns: true,
            loadingOverlayComponent: 'customLoadingOverlay',
            noRowsOverlayComponent: 'customNoRowsOverlay',
        }),
        [onGridReady, loadingRenderer, props.columnDefs],
    );

    const setCurrentPage = useCallback((newPage: number) => {
        gridApi?.paginationGoToPage(newPage);
    }, [gridApi]);

    const changePageSize = useCallback((newPageSize: number) => {
        gridApi?.paginationSetPageSize(newPageSize);
        setPageSize(newPageSize);
    }, [gridApi]);

    return (
        <div className={`udc-theme ag-theme-va ${styles.grid}`} datatestid={props.dataTestId} data-ready={isReady}>
            { columnApi && <GridHeader columnApi={columnApi} setSearchQuery={setSearchQuery} isCampaignGrid={isCampaignGrid ?? false} hiddenDimensions={hiddenDimensions} hiddenMetrics={hiddenMetrics} />}
            <AgGridReact gridOptions={gridOptions} modules={[InfiniteRowModelModule]} domLayout='autoHeight' />
            <GridFooter>
                <GridPagination
                    totalRows={totalRows}
                    setCurrentPage={setCurrentPage}
                    pageSize={pageSize}
                    setPageSize={changePageSize} />
            </GridFooter>
        </div >
    );
};
