import gql from "graphql-tag";

const query = gql`
query fetchAdvertiserSasMetadata($advertiserId: Long!) {
    sasAdvertiserMetadata(advertiserId: $advertiserId) {
        id
        name
        verticalName
    }
}
`;
export default query;
