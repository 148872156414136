import React from 'react';
import styles from './Layout.module.css';
import classNames from 'classnames';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { Header } from './Header';

export interface LayoutProps {
    readonly breadcrumbs: React.ReactElement[];
    readonly header?: React.ReactNode;
    readonly children?: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = props => {
    return (
        <div className={styles.layout}>
            <nav className={classNames(styles.nav, styles.border)} datatestid="breadcrumbs">
                <Breadcrumbs breadcrumbs={props.breadcrumbs} />
            </nav>
            <Header>
                {props.header}
            </Header>
            <main className={styles.main}>{props.children}</main>
        </div>
    );
};
