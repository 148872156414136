import React from 'react';
import { Jumbo } from '../../containers/Jumbo';
import { CPD_METRICS } from '../../lib/types';

import CAMPAIGN_CHART from '../../graphql/cpd.chart.gql';
import CAMPAIGN_METRICS from '../../graphql/cpd.metrics.gql';

export interface CampaignJumboProps {
    readonly campaignId: number;
}

export const CampaignJumbo: React.FC<CampaignJumboProps> = ({ campaignId }) => {
    return <Jumbo
        queryChart={CAMPAIGN_CHART}
        queryMetrics={CAMPAIGN_METRICS}
        queryVars={{ campaignId }}
        dictionary={CPD_METRICS}
        dataTestId="campaigns" />;
};
