import {buildUrlApiRest} from './url-helpers';
import {DateRangeType} from '../lib/types';
import {Ticker} from '../lib/tickers';
import {Feedback} from '../components/FeedbackModal/FeedbackContent';

export interface DateRangeRequest {
    epochDateRange: DateRangeType;
}

export interface GridRequest {
    startRow: number;
    endRow: number;
    sortModel: any[];
}

export interface ChartRequest {
    groupBy?: string;
}

const getAuthorization = () => {
    return (localStorage.getItem('Authorization') || '').replace(/^"(.*)"$/, '$1');
};

export const getChartParams = (req: ChartRequest, searchParams?: URLSearchParams): URLSearchParams => {
    const params = searchParams ?? new URLSearchParams();
    params.append('groupBy', req.groupBy ?? Ticker.hours); //TODO: should be removed as soon api add support for total counters
    return params;
};

export const getDateRangeParams = (req: DateRangeRequest, searchParams?: URLSearchParams): URLSearchParams => {
    const params = searchParams ?? new URLSearchParams();
    params.append('from', req.epochDateRange.from.toString());
    params.append('to', req.epochDateRange.to.toString());
    return params;
};

export const getGridParams = (req: GridRequest, searchParams?: URLSearchParams): URLSearchParams => {
    const params = searchParams ?? new URLSearchParams();
    params.append('offset', req.startRow.toString());
    params.append('limit', (req.endRow - req.startRow).toString());
    if (req.sortModel && req.sortModel.length > 0) {
        params.append('sortProperty', req.sortModel[0].colId);
        params.append('sortDirection', req.sortModel[0].sort.toUpperCase());
    }
    return params;
};

export async function getPage<T>(resoure: string): Promise<{ rows: T[]; total: number }> {
    return (await get(resoure)) || { rows: [], total: 0 };
}

export async function getList<T>(resoure: string): Promise<T[]> {
    return (await get(resoure)) || [];
}

export const get = async <T>(resoure: string): Promise<T | null> => {
    try {
        let response = await fetch(buildUrlApiRest(resoure), {
            credentials: 'same-origin',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: getAuthorization(),
                // va-user-id,
                // va-account-id
            },
        });
        if (response.ok) {
            return await response.json();
        } else {
            console.error(`response.status: ${response.status} response.statusText: ${response.statusText}`);
            return null;
        }
    } catch (e) {
        console.error(`fetch error: ${(e as Error).message}`, e);
        return null;
    }
};

export const postFeedback = async (feedback: Feedback, userId: number | undefined) => {
    const markdown = `### User\n[${userId}](https://platform.sizmek.com/#/spa/user/${userId}/userEdit)\n### Feedback message\n\n\n${feedback.feedbackMessage}`;
    const payload: Feedback = { ...feedback, feedbackMessage: markdown };

    return await fetch('/spa/visual-analytics/rest/feedback', {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            Authorization: getAuthorization(),
        },
    });
};
