import gql from "graphql-tag";
import cpdMetrics from "./cpdMetrics.dto.gql";

const query = gql`
query fetchCampaignByIdChart(
    $prevFrom: Long!
    $prevTo: Long!
    $from: Long!
    $to: Long!
    $tzOffsetHours: Int
    $campaignId: Long!
    $filterByPlacementTypes: [Int!]
    $groupBy: ChartScaleType
) {
    prev: campaignByIdChart(
        from: $prevFrom
        to: $prevTo
        tzOffsetHours: $tzOffsetHours
        groupBy: $groupBy
        campaignId: $campaignId
        filterByPlacementTypes: $filterByPlacementTypes
    ) {
        epochSec
        ...cpdMetrics
    }
    curr: campaignByIdChart(
        from: $from
        to: $to
        tzOffsetHours: $tzOffsetHours
        groupBy: $groupBy
        campaignId: $campaignId
        filterByPlacementTypes: $filterByPlacementTypes
    ) {
        epochSec
        ...cpdMetrics
    }
}
${cpdMetrics}
`;
export default query;
