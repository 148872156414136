import React from 'react';
import { ColDef, ICellRendererParams } from '@ag-grid-community/core';
import { Grid, columnTypes as ct } from '../../containers/Grid';
import { CampaignLink } from '../../components/PageLink';
import { TwoRowsCellRenderer } from '../../containers/Grid/renders/cellRenders';
import { commonMetricColumns, typesOfColumn } from '../Common/grids';

import CAMPAIGNS_BY_ADVERTISER from '../../graphql/campaigns.grid.gql';

const metricColumnDefs = commonMetricColumns.filter(col => !typesOfColumn(col).includes(ct.isCPDOnly));

const columnDefs: ColDef[] = [
    {
        field: 'campaignName',
        headerName: 'Campaign',
        type: [ct.isDefault, ct.isDimension, ct.text],
        // headerTooltip: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Doloribus accusamus est veritatis.',
        cellRenderer: (params: ICellRendererParams) => {
            return (
                <TwoRowsCellRenderer desc={params.data?.campaignId}>
                    {params.data && <CampaignLink
                        campaign={{ id: params.data.campaignId, name: params.value }}
                    />}
                </TwoRowsCellRenderer>
            );
        },
        minWidth: 450,
    },
    ...metricColumnDefs,
];

export interface CampaignsGridProps {
    readonly advertiserId: number;
}

export const CampaignsGrid: React.FC<CampaignsGridProps> = ({ advertiserId }) => (
    <Grid query={CAMPAIGNS_BY_ADVERTISER} queryVars={{ advertiserId }} columnDefs={columnDefs} dataTestId="campaigns-grid" />
);
