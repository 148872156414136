import React, {useState} from 'react';
import {Dropdown} from '../../components/Dropdown';
import {Dimension, Metric, METRICS} from '../../lib/types';
import {TreeMapContainer} from '../../containers/TreeMap';
import {MiniChart} from '../../containers/MiniChart';
import {useLocalStorage} from '../../hooks/useLocalStorage';

let CPD_DIMENSIONS_TREEMAP: any = {
    siteName: 'Site',
    placementSize: 'Placement size',
    platform: 'Device OS',
    deviceType: 'Device type',
};

export interface CampaignTreeMapProps {
    readonly campaignId: number;
}

export const CampaignTreeMap: React.FC<CampaignTreeMapProps> = (props) => {
    const [hackathonFF] = useLocalStorage('hackathonFF');

    const [metric, setMetric] = useState<Metric>('impressions');
    const [dimension, setDimension] = useState<Dimension>('deviceType');

    if (hackathonFF === "on") {
        CPD_DIMENSIONS_TREEMAP['city' as Dimension] = 'City' as any;
        CPD_DIMENSIONS_TREEMAP['state' as Dimension] = 'State' as any;
        CPD_DIMENSIONS_TREEMAP['country' as Dimension] = 'Country' as any;
    }

    return (
        <MiniChart header={<>
            <Dropdown
                selected={metric}
                dictionary={METRICS}
                onChange={setMetric} />
            <span>by</span>
            <Dropdown
                selected={dimension}
                dictionary={CPD_DIMENSIONS_TREEMAP as any}
                onChange={setDimension} />
        </>} dataTestId="treeMap">
            <TreeMapContainer campaignId={props.campaignId} metric={metric} dimension={dimension} />
        </MiniChart >
    );
};
