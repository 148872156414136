import React from 'react';
import { ColDef, ICellRendererParams } from '@ag-grid-community/core';
import { Grid, columnTypes as ct } from '../../containers/Grid';
import { AdvertiserLink } from '../../components/PageLink';
import { TwoRowsCellRenderer } from '../../containers/Grid/renders/cellRenders';
import { commonMetricColumns, typesOfColumn } from '../Common/grids';
import { verticalMappings } from '../../lib/dimensions';

import ADVERTISERS_GRID from '../../graphql/advertisers.grid.gql';

const metricColumnDefs = commonMetricColumns.filter(col => !typesOfColumn(col).includes(ct.isCPDOnly));

const columnDefs: ColDef[] = [
    {
        headerName: 'Advertiser',
        field: 'advertiserName',
        type: [ct.isDefault, ct.isDimension, ct.text],
        cellRenderer: (params: ICellRendererParams) => {
            return (
                <TwoRowsCellRenderer desc={params.data?.advertiserId}>
                    {params.data && <AdvertiserLink
                        advertiser={{ id: params.data.advertiserId, name: params.value }}
                    />}
                </TwoRowsCellRenderer>
            );
        },
        minWidth: 450,
    },
    {
        headerName: 'Vertical',
        field: 'verticalName',
        type: [ct.isDefault, ct.isDimension, ct.text],
        minWidth: 200,
        cellRenderer: (params: ICellRendererParams) => {
            return params.valueFormatted || <span className="vaNotAvailable">Not available</span>;
        },
        refData: verticalMappings,
    },
    // {
    //     headerName: 'Campaigns',
    //     field: 'totalCampaigns',
    //     type: [ct.isDefault, ct.isPinned, ct.numeric],
    //     sortable: false,
    // },
    ...metricColumnDefs,
];

export interface AdvertisersGridProps {
    readonly accountId: number;
}

export const AdvertisersGrid: React.FC<AdvertisersGridProps> = ({ accountId }) => (
    <Grid query={ADVERTISERS_GRID} queryVars={{ accountId }} columnDefs={columnDefs} dataTestId="advertiser-grid" />
);
