import gql from "graphql-tag";
import metrics from "./metrics.dto.gql";

const query = gql`
query fetchAccountsMetrics($prevFrom: Long!, $prevTo: Long!, $from: Long!, $to: Long!, $tzOffsetHours: Int, $groupBy: ChartScaleType) {
    prev: accountsChart(from: $prevFrom, to: $prevTo, tzOffsetHours: $tzOffsetHours, groupBy: $groupBy) {
        epochSec
        ...metrics
    }
    curr: accountsChart(from: $from, to: $to, tzOffsetHours: $tzOffsetHours, groupBy: $groupBy) {
        epochSec
        ...metrics
    }
}
${metrics}
`;
export default query;
