import { commonMetricColumns } from '../../pages/Common/grids';
import { GridState, ColumnActionTypes, SHOW_COLUMN, HIDE_COLUMN } from './actions';
import { columnTypes } from '../../containers/Grid';

const initialState: GridState = {
    hiddenMetrics: commonMetricColumns
        .filter(column => !column.type?.includes(columnTypes.isDefault) || column.type?.includes(columnTypes.isCPDOnly))
        .map(column => column.field ?? ''),
    hiddenDimensions: [],
    cpdHiddenMetrics: commonMetricColumns.filter(column => !column.type?.includes(columnTypes.isDefault)).map(column => column.field ?? ''),
    cpdHiddenDimensions: [],
};

export function gridReducer(state = initialState, action: ColumnActionTypes): GridState {
    switch (action.type) {
        case SHOW_COLUMN:
            return {
                hiddenMetrics:
                    !action.payload.isCPD && !action.payload.isDimension
                        ? state.hiddenMetrics.filter(column => column !== action.payload.column)
                        : state.hiddenMetrics,
                hiddenDimensions:
                    !action.payload.isCPD && action.payload.isDimension
                        ? state.hiddenDimensions.filter(column => column !== action.payload.column)
                        : state.hiddenDimensions,
                cpdHiddenMetrics:
                    action.payload.isCPD && !action.payload.isDimension
                        ? state.cpdHiddenMetrics.filter(column => column !== action.payload.column)
                        : state.cpdHiddenMetrics,
                cpdHiddenDimensions:
                    action.payload.isCPD && action.payload.isDimension
                        ? state.cpdHiddenDimensions.filter(column => column !== action.payload.column)
                        : state.cpdHiddenDimensions,
            };
        case HIDE_COLUMN:
            return {
                hiddenMetrics:
                    !action.payload.isCPD && !action.payload.isDimension ? [...state.hiddenMetrics, action.payload.column] : state.hiddenMetrics,
                hiddenDimensions:
                    !action.payload.isCPD && action.payload.isDimension ? [...state.hiddenDimensions, action.payload.column] : state.hiddenDimensions,
                cpdHiddenMetrics:
                    action.payload.isCPD && !action.payload.isDimension ? [...state.cpdHiddenMetrics, action.payload.column] : state.cpdHiddenMetrics,
                cpdHiddenDimensions:
                    action.payload.isCPD && action.payload.isDimension
                        ? [...state.cpdHiddenDimensions, action.payload.column]
                        : state.cpdHiddenDimensions,
            };
        default:
            return state;
    }
}
