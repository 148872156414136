import React from 'react';
import styles from './Overlay.module.css';

export interface OverlayProps {
    readonly zIndex?: number;
    readonly color?: string;
    readonly onClick?: () => void;
}

export class Overlay extends React.Component<OverlayProps> {
    componentDidMount() {
        document.body.style.overflow = 'hidden';
    }

    componentWillUnmount() {
        document.body.style.overflow = 'auto';
    }

    render() {
        const { zIndex, color } = this.props;
        return <div className={styles.overlay} style={{ zIndex, backgroundColor: color }} onClickCapture={this.clicked} />;
    }

    clicked = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (this.props.onClick) {
            this.props.onClick();
        }
    };
}
