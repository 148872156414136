import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import * as actions from '../store/grid/actions';
import { AppStore } from '../store';

export const useGridState = (): actions.GridState => {
    return useSelector((appStore: AppStore) => appStore.grid, shallowEqual);
};

export const useGridColumnsManagement = (): {
    hide: (column: string, isDimension: boolean, isCPD: boolean) => void;
    show: (column: string, isDimension: boolean, isCPD: boolean) => void;
} => {
    const dispatch: Dispatch<any> = useDispatch();
    const hide = useCallback(
        (column: string, isDimension: boolean, isCPD: boolean = false) => dispatch(actions.hideColumn(column, isDimension, isCPD)),
        [dispatch],
    );
    const show = useCallback(
        (column: string, isDimension: boolean, isCPD: boolean = false) => dispatch(actions.showColumn(column, isDimension, isCPD)),
        [dispatch],
    );
    return { hide, show };
};
