import React from 'react';
import { Dropdown, DropdownItem } from '@amzn/storm-ui';
import { TIMEZONES, TimeZoneId, getTimezoneById } from '../../lib/types';
import styles from './TimezonePicker.module.css';

export interface TimezomePickerProps {
    readonly timeZoneId?: TimeZoneId;
    readonly onChange: (timeZoneId: TimeZoneId) => void;
}

export const TimezomePicker: React.FC<TimezomePickerProps> = ({ timeZoneId, onChange }) => {
    const handleTzLabel = (_label: string, timeZoneId: TimeZoneId) => getTimezoneById(timeZoneId).shortLabel;

    return (
        <div className={styles.container}>
            <Dropdown selectedValue={timeZoneId} onChange={onChange} onOverrideLabel={handleTzLabel}>
                {TIMEZONES.map(tz => (
                    <DropdownItem key={tz.id} value={tz.id}>
                        {tz.label}
                    </DropdownItem>
                ))}
            </Dropdown>
        </div>
    );
};
