import gql from "graphql-tag";

const query = gql`
query fetchAccountSasMetadata($accountId: Long!) {
    sasAccountMetadata(accountId: $accountId) {
        id
        name
        marketName
    }
}
`;
export default query;
