import React from 'react';
import styles from './Layout.module.css';
import { Layout } from './Layout';
import { Panel } from './Panel';

export interface DashboardProps {
    breadcrumbs: React.ReactElement[];
    header: React.ReactNode;
    jumbo: React.ReactNode;
    charts?: React.ReactNode[];
    grid: React.ReactNode;
}

export const Dashboard: React.FC<DashboardProps> = ({ breadcrumbs, header, jumbo, grid, charts }) => {
    return (
        <Layout breadcrumbs={breadcrumbs} header={header}>
            <Panel className={styles.jumbo} border>
                {jumbo}
            </Panel>
            {charts ? (
                <section className={styles.chartsSection}>
                    {charts.map((chart, key) => (
                        <Panel key={key} className={styles.miniChartPanel} border>
                            {chart}
                        </Panel>
                    ))}
                </section>
            ) : null}
            <Panel className={styles.grid} border>
                {grid}
            </Panel>
        </Layout>
    );
};
