import React from 'react';
import { Jumbo } from '../../containers/Jumbo';
import { METRICS } from '../../lib/types';

import CAMPAIGNS_CHART from '../../graphql/campaigns.chart.gql';
import CAMPAIGNS_METRICS from '../../graphql/campaigns.metrics.gql';

export interface CampaignsJumboProps {
    readonly advertiserId: number;
}

export const CampaignsJumbo: React.FC<CampaignsJumboProps> = ({ advertiserId }) => {
    return <Jumbo
        queryChart={CAMPAIGNS_CHART}
        queryMetrics={CAMPAIGNS_METRICS}
        queryVars={{ advertiserId }}
        dictionary={METRICS}
        dataTestId="campaigns" />;
};
