import React, { useState, useRef, ChangeEvent, useCallback, useEffect } from 'react';
import styles from './PopoverFilter.module.css';
import { Button, Popover, Checkbox, Icon, TextButton, CheckboxGroup } from '@amzn/storm-ui';
import classNames from 'classnames';
import { Overlay } from '../Overlay';

interface Item {
    key: string,
    value: string,
}

interface PopoverFilterProps {
    onSave: (items: string[] | null) => void;
    items: Item[];
    checkedItems: string[];
    labelButton: string;
    labelPopover: string;
    dataTestId: string;
}

export const PopoverFilter: React.FC<PopoverFilterProps> = props => {
    const { items, onSave } = props;

    const [isActive, setIsActive] = useState<boolean>(false);
    const [checkedItems, setCheckedItems] = useState<string[]>([]);

    useEffect(() => {
        setCheckedItems(items.filter(it => props.checkedItems.includes(it.key)).map(it => it.key));
    }, [props.checkedItems, items, isActive]);

    const toggleIsActive = useCallback(() => {
        setIsActive(!isActive)
    }, [isActive, setIsActive]);

    const stickTo = useRef(null);

    const isCheckedItem = (key: string): boolean => checkedItems.includes(key);
    const isCheckedItems = items.every(item => isCheckedItem(item.key));

    const handleOnChangeAll = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setCheckedItems(event.target.checked ? items.map(it => it.key) : []);
    }, [items]);

    const handleOnSave = useCallback(() => {
        setIsActive(false);
        onSave(checkedItems);
    }, [checkedItems, onSave]);

    const handleOnCancel = useCallback(() => {
        setIsActive(false);
    }, []);

    const handleOnChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setCheckedItems(event.target.checked ? [...checkedItems, event.target.id] : checkedItems.filter(it => it !== event.target.id));
    }, [checkedItems]);

    const handleOnRemove = useCallback(() => {
        setIsActive(false);
        onSave(null);
    }, [onSave]);

    const isDisabledSaveButton = checkedItems.length === 0;

    return (
        <div className={styles.stateFilter} datatestid={`${props.dataTestId}-button`}>
            <div>Filter by:</div>
            <div ref={stickTo}>
                <Button ref={stickTo} className={classNames(styles.filterButton, styles.noBorder)} small onClick={toggleIsActive}>
                    {props.labelButton} &nbsp;
                    <Icon type="angle-down" /></Button>
            </div>
            {isActive && <Overlay zIndex={2} />}
            <Popover
                position="right"
                align="start"
                isOpen={isActive}
                onClose={handleOnCancel}
                anchorEl={stickTo.current}
                withBackdrop>
                <div className={styles.popover} datatestid={`${props.dataTestId}-popover`}>
                    <div className={styles.header}>
                        <span datatestid="description">{props.labelPopover}</span>
                        {(!items || checkedItems.length > 0) && <TextButton onClick={handleOnRemove}>Remove</TextButton>}
                    </div>
                    <div className={styles.content}>
                        <CheckboxGroup>
                            <Checkbox
                                className={`${props.dataTestId}-select-deselect-all`}
                                id={'select-deselect-all'}
                                checked={isCheckedItems}
                                onChange={handleOnChangeAll}
                                label={"Select/Deselect all"} />
                            {items.sort((item, item1) => item.value > item1.value ? 1 : -1)
                                .map((item, index) =>
                                    <Checkbox
                                        className={`${props.dataTestId}-item-container`}
                                        key={item.key + index}
                                        id={item.key}
                                        checked={isCheckedItem(item.key)}
                                        onChange={handleOnChange}
                                        label={item.value} />
                                )}
                        </CheckboxGroup>
                    </div>
                    <div className={styles.footer}>
                        <TextButton onClick={handleOnCancel}>Cancel</TextButton>
                        <Button primary onClick={handleOnSave} disabled={isDisabledSaveButton}>Apply</Button>
                    </div>
                </div>
            </Popover>
        </div>
    );
};