import gql from "graphql-tag";

const query = gql`
query fetchCampaignSasMetadata($campaignId: Long!) {
    sasCampaignMetadata(campaignId: $campaignId) {
        id
        name
        startDate
        endDate
        status
    }
}
`;
export default query;
