import React from 'react';
import styles from './Widget.module.css';
import classNames from 'classnames';
import { DASH, dt as calc, formatPercent, formatDateRange, formatMetric } from '../../lib/formatters';
import { HelpTip, Icon, Text } from '@amzn/storm-ui';
import { Metric, MetricValues, METRICS, CPD_METRICS, DateRangeType, allMetrics } from '../../lib/types';

export interface TagProps {
    readonly children?: React.ReactNode;
}

const Tag: React.FC<TagProps> = ({ children }) => <span className={styles.tag}>{children}</span>;

interface MetricHelpTipProps {
    readonly metric: Metric;
}

const MetricHelpTip: React.FC<MetricHelpTipProps> = ({ metric }) => {
    const info = allMetrics.find(m => m.field === metric);
    if (info) {
        return (
            <HelpTip>
                {/* @ts-ignore */}
                <Text type="h3">
                    {info.title} {info.tag && <Tag>{info.tag}</Tag>}
                </Text>
                {/* @ts-ignore */}
                <Text type="p">{info.description}</Text>
            </HelpTip>
        );
    } else {
        return null;
    }
};

export interface WidgetProps {
    currentRange: DateRangeType;
    previousRange: DateRangeType;
    selectedMetric: Metric;
    dictionary: typeof METRICS | typeof CPD_METRICS;
    values: Map<Metric, MetricValues>;
    dataTestId: string;
}

export const SideWidget: React.FC<WidgetProps> = props => {
    const curr = props.values.get(props.selectedMetric)?.curr || 0;
    const prev = props.values.get(props.selectedMetric)?.prev || 0;

    const dt: number = calc(curr, prev);
    const up = dt > 0;
    const down = dt < 0;

    return (
        <div className={styles.sideWidget} datatestid={`${props.dataTestId}-comparison-widget`}>
            <div className={styles.sideLabel} datatestid="metric-name">
                {Object.entries(props.dictionary).find(([key]) => key === props.selectedMetric)?.[1]}
                <MetricHelpTip metric={props.selectedMetric} />
            </div>

            <div>
                <span className={classNames({ [styles.up]: up, [styles.arrowUp]: up, [styles.down]: down, [styles.arrowDown]: down })}>
                    {up && <Icon type="long-arrow-alt-up" />}
                    {down && <Icon type="long-arrow-alt-down" />}
                    <span className={styles.sideProgess} datatestid="metric-difference">
                        {formatPercent(Math.abs(dt), DASH, 1)}
                    </span>
                </span>
            </div>

            <div className={styles.sideValues} datatestid="metric-comparison-statistics">
                {!!(curr || prev) && (
                    <span>
                        {formatDateRange(props.previousRange)}:&nbsp;{formatMetric(prev, props.selectedMetric)}
                    </span>
                )}
            </div>
        </div>
    );
};
