import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider, defaultTheme, Theme } from '@amzn/storm-ui';

const vaTheme: Theme = {
    ...defaultTheme,
    modal: {
        ...defaultTheme.modal,
        overlay: 'rgba(255, 255, 255, 0.6)',
        zIndex: 2 as any,
    },
};

const root = createRoot(document.getElementById('va-root')!);
root.render(
    <React.StrictMode>
        <ThemeProvider theme={vaTheme}>
            <App />
        </ThemeProvider>
    </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
