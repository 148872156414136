import React from 'react';
import { Dashboard } from '../../layouts/Dashboard';
import { CampaignGrid } from './CampaignGrid';
import { CampaignJumbo } from './CampaignJumbo';
import { useCampaignParam } from '../../hooks/useParams';
import { Loading, AppError } from '../../components/Messages';
import { CampaignHeatMap } from './CampaignHeatMap';
import { CampaignMiniBars } from './CampaignMiniBars';
import { CampaignTreeMap } from './CampaignTreeMap';
import { AccountLink, AccountsLink, AdvertiserLink } from '../../components/PageLink';
import { CampaignMeta } from '../../layouts/Header';

export const CampaignPage: React.FC = () => {
    const { campaign, advertiser, account, error, loading } = useCampaignParam();

    if (loading) return <Loading />;
    if (error) return <AppError e={error} />;

    if (campaign && advertiser && account) {
        return (
            <Dashboard
                breadcrumbs={[
                    <AccountsLink label="All accounts" />,
                    <AccountLink withId withType account={account} />,
                    <AdvertiserLink withId withType advertiser={advertiser} />,
                ]}
                header={<CampaignMeta entity={campaign} />}
                jumbo={<CampaignJumbo campaignId={campaign.id} />}
                charts={[
                    <CampaignHeatMap campaignId={campaign.id} />,
                    <CampaignMiniBars campaignId={campaign.id} />,
                    <CampaignTreeMap campaignId={campaign.id} />,
                ]}
                grid={<CampaignGrid campaignId={campaign.id} />}
            />
        );
    }

    return <AppError />;
};
