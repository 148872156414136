import React from 'react';
import { EntityType } from '../../lib/types';
import { EntityLink } from './EntityLink';

export interface AdvertiserLinkProps {
    readonly advertiser: EntityType;
    readonly withId?: boolean;
    readonly withType?: boolean;
}

export const AdvertiserLink: React.FC<AdvertiserLinkProps> = props => (
    <>
        {props.withType && <span>Advertiser:&nbsp;</span>}
        <EntityLink to={`/advertisers/${props.advertiser.id}`} title={props.advertiser.name}>
            {props.advertiser.name}
            {props.withId && <span>&nbsp;({props.advertiser.id})</span>}
        </EntityLink>
    </>
);
