import React, { useCallback, useMemo, useState } from 'react';
import styles from './Dropdown.module.css';
import { useRandomString } from '../../hooks/useRandomString';
import FilterDropdown from '@amzn/storm-ui-filter-dropdown';
import { METRICS, CPD_METRICS, CPD_DIMENSIONS } from '../../lib/types';
import { Dropdown as NoFilterDropdown, DropdownItem } from '@amzn/storm-ui';

interface DropdownItemProps {
    readonly name: string;
    // readonly value: number | undefined;
    readonly matches?: number[][];
}

function highlightText(text: string, matches: number[][] | null): React.ReactFragment {
    let ranges = [...(matches ?? [])];
    ranges.sort((a, b) => b[0] - a[0]); // descending

    let result = [];
    for (const [start, end] of ranges) {
        result.push(text.substring(end + 1));
        result.push(
            <span key={result.length} className={styles.highlight}>
                {text.substring(start, end + 1)}
            </span>
        );
        text = text.substring(0, start);
    }
    result.push(text);
    result.reverse();
    return result;
}

const MetricsDropdownItem: React.FC<DropdownItemProps> = ({ name, matches = null }) => (
    <div className={styles.item}>
        <span>{highlightText(name, matches)}</span>
        {/* TODO: add gray styling for 0 elements */}
        {/* {value && value !== 0
            ? <span className={styles.dropdownValue}>{' ('}{formatNumber(value, DASH)}{')'}</span>
            : null} */}
    </div>
);

export interface DropdownProps<T> {
    readonly selected: T;
    readonly dictionary: typeof METRICS | typeof CPD_METRICS | typeof CPD_DIMENSIONS;
    readonly onChange: (item: T) => void;
    readonly withFilter?: boolean;
}

interface Match {
    readonly indices?: number[][];
}

interface FilteredItem {
    readonly item?: any;
    readonly matches?: Match[];
}

interface Choice {
    readonly key: string;
    readonly name: string;
    readonly matches?: number[][];
}

export const Dropdown = <T extends string>(props: DropdownProps<T>) => {
    const id = useRandomString();

    const { selected, onChange, dictionary, withFilter } = props;
    const items: Choice[] = Object.entries(dictionary)
        .map(([key, name]) => ({ key, name }))
        .sort((m1, m2) => m1.name > m2.name ? 1 : -1);

    const overrideLabel = useCallback((_label: string, value: string) => {
        return (dictionary as { [key: string]: string })[value];
    }, [dictionary]);

    const handleChange = useCallback((item: T) => {
        onChange(item);
    }, [onChange]);

    const [filteredItems, setFilteredItems] = useState<FilteredItem[] | null>(null);

    const handleFilterChange = (results: FilteredItem[], value: string) => {
        if (value) {
            setFilteredItems(results);
        } else {
            setFilteredItems(null);
        }
    };

    const handleFilterClear = useCallback(() => {
        setFilteredItems(null);
    }, []);

    const options = {
        keys: ['name'],
        threshold: 0.2,
        includeMatches: true,
    };

    const filtered = items
        .flatMap(item => {
            if (filteredItems == null) {
                return [item];
            }
            let filteredField = filteredItems.find(f => f.item.key === item.key);
            if (filteredField != null) {
                const matches = (filteredField.matches ?? []).flatMap(m => m.indices ?? []);
                return [{ ...item, matches }];
            } else {
                return [];
            }
        });

    const Dropdown = useMemo(() => (props: any) =>
        withFilter ? <FilterDropdown {...props} /> : <NoFilterDropdown {...props} />, [withFilter]);

    return (
        <Dropdown
            searchInputId={id}
            small
            onChange={handleChange}
            selectedValue={selected}
            onOverrideLabel={overrideLabel}
            onFilterChange={handleFilterChange}
            onFilterClear={handleFilterClear}
            items={items}
            options={options}
        >
            {filtered.map(item => (
                <DropdownItem key={item.key} value={item.key}>
                    <MetricsDropdownItem
                        name={item.name}
                        // value={values?.get(item.key as Dictionary)?.curr} 
                        matches={item.matches} />
                </DropdownItem>
            ))}
        </Dropdown>
    );
};
