import React from 'react';
import styles from './Widget.module.css';
import classNames from 'classnames';
import { formatMetric } from '../../lib/formatters';
import { Metric, MetricValues, METRICS, CPD_METRICS } from '../../lib/types';
import { Dropdown } from './../Dropdown';

export interface WidgetProps {
    selectedMetric: Metric;
    values: Map<Metric, MetricValues>;
    dictionary: typeof METRICS | typeof CPD_METRICS;
    // dictionary: Dictionary;
    setMetricType: (metric: Metric) => void;
    className?: string;
    dataTestId: string;
}

export const HeaderWidget: React.FC<WidgetProps> = props => {
    const value = props.values.get(props.selectedMetric)?.curr;
    const valueFormatted = formatMetric(value, props.selectedMetric);

    return (
        <div className={classNames(styles.widget, styles.headerWidget, props.className)} datatestid={`${props.dataTestId}-total-widget`}>
            <div className={styles.headerLabel}>
                <Dropdown
                    selected={props.selectedMetric}
                    // values={props.values}
                    dictionary={props.dictionary}
                    onChange={props.setMetricType}
                    withFilter
                />
            </div>

            <div className={styles.headerValue} datatestid="selected-metric-value">
                {valueFormatted}
            </div>
        </div>
    );
};
