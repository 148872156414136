import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styles from './Link.module.css';

export interface EntityLinkProps {
    readonly to: string;
    readonly title?: string;
    readonly children?: ReactNode;
}

export const EntityLink: React.FC<EntityLinkProps> = ({ to, title, children }) => (
    <Link className={styles.link} to={to} title={title}>
        {children}
    </Link>
);
