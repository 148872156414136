import React from 'react';
import { Dropdown, DropdownItem } from '@amzn/storm-ui';

const feedbackOptions: string[] = ['General feedback', 'Report an issue', 'Feature request'];

export const defaultFeedbackType = feedbackOptions[0];

interface FeedbackTypeDropdownProps {
    readonly text: string;
    readonly onChange: (v: string) => void;
}

export const FeedbackTypeDropdown: React.FC<FeedbackTypeDropdownProps> = ({ onChange, text }) => {
    return (
        <>
            <Dropdown onChange={onChange} selectedValue={text || defaultFeedbackType}>
                {feedbackOptions.map(feedback => (
                    <DropdownItem value={feedback} key={feedback}>
                        {feedback}
                    </DropdownItem>
                ))}
            </Dropdown>
        </>
    );
};
