import { SearchInput } from "@amzn/storm-ui";
import React, { useCallback, useEffect, useRef, useState } from "react";

interface GridSearchProps {
    setSearchQuery: (searchQuery: string | null) => void,
}

export const GridSearch: React.FC<GridSearchProps> = ({ setSearchQuery }) => {

    const [searchValue, setSearchValue] = useState<string>('');
    const searchTimer = useRef<ReturnType<typeof setTimeout>>();

    const handleChange = useCallback((event: any) => setSearchValue(event.target.value), [setSearchValue]);

    const handleClear = useCallback((event: any) => {
        setSearchValue('');
        setSearchQuery(null);
    }, [setSearchValue, setSearchQuery]);

    useEffect(() => {
        searchTimer.current && clearTimeout(searchTimer.current);
        if (searchValue) {
            searchTimer.current = setTimeout(() => {
                searchValue.trim().length !== 1 && setSearchQuery(searchValue.trim() || null);
            }, 800);
        } else {
            setSearchQuery(null);
        }
    }, [setSearchQuery, searchValue]);

    return <SearchInput
        id="grid-search"
        placeholder="Search"
        value={searchValue}
        onChange={handleChange}
        onClear={handleClear}
    />
}