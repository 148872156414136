import gql from "graphql-tag";

const query = gql`
query campaignByIdTreemap(
    $metric: String!
    $dimension: String!
    $from: Long!
    $to: Long!
    $tzOffsetHours: Int
    $campaignId: Long!
    $filterByPlacementTypes: [Int!]
) {
    treemap: campaignByIdTreemap(
        metric: $metric
        dimension: $dimension
        from: $from
        to: $to
        tzOffsetHours: $tzOffsetHours
        campaignId: $campaignId
        filterByPlacementTypes: $filterByPlacementTypes
    ) {
        dimension
        metric
    }
}
`;
export default query;
