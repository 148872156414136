import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { ColumnApi } from '@ag-grid-community/core';
import { useGridColumnsManagement } from '../../hooks/useGridColumns';
import { DASH } from '../../lib/formatters';
import { columnTypes } from '.';
import { ModalFilter } from '../../components/PopoverFilter';

export interface GridColumnsProps {
    readonly isCampaignGrid: boolean;
    readonly columnApi: ColumnApi;
    readonly hiddenDimensions: string[];
    readonly hiddenMetrics: string[];
}

const LABEL = {
    default: 'Columns: Default',
    custom: 'Columns: Custom',
}

export const GridColumns: React.FC<GridColumnsProps> = props => {
    const { hide, show } = useGridColumnsManagement();
    const { hiddenDimensions, hiddenMetrics } = props;
    const [labelButton, setLabelButton] = useState<string>(LABEL.default);

    // TODO: investigave hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => [...hiddenMetrics, ...hiddenDimensions].forEach(column => props.columnApi.setColumnVisible(column, false)), []);

    const handleColumnVisibility = useCallback((column: string, visible: boolean) => {
        props.columnApi.setColumnVisible(column, visible);
        const isDimension = !!props.columnApi.getColumn(column)?.getColDef().type?.includes(columnTypes.isDimension);
        visible ? show(column, isDimension, props.isCampaignGrid) : hide(column, isDimension, props.isCampaignGrid);
    }, [props.isCampaignGrid, hide, props.columnApi, show]);

    const visibleColumns = useMemo(() => (props.columnApi.getColumns() ?? [])
        .map(column => column.getId())
        .filter(key => !hiddenMetrics.includes(key) && !hiddenDimensions.includes(key)), [hiddenMetrics, hiddenDimensions, props.columnApi]);

    const columns = useMemo(() => (props.columnApi.getColumns() ?? [])
        .filter(column => props.isCampaignGrid || !column.getColDef().type?.includes(columnTypes.isDimension))
        .map(column => {
            return {
                key: column.getId(),
                value: column.getDefinition().headerName ?? DASH,
                group: column.getColDef().type?.includes(columnTypes.isDimension) ? 'Dimensions' : 'Metrics',
            }
        }), [props.columnApi, props.isCampaignGrid]);

    const defaultItems = useMemo(() => (props.columnApi.getColumns() ?? [])
        .filter(column => column.getColDef().type?.includes(columnTypes.isDefault))
        .map(column => column.getId()), [props.columnApi]);

    useEffect(() => {
        setLabelButton((visibleColumns.length === defaultItems.length) &&
            visibleColumns.every(item => defaultItems.includes(item)) ? LABEL.default : LABEL.custom);
    }, [visibleColumns, defaultItems]);

    const handleOnSave = useCallback((checkedItems: string[]) => {
        columns.forEach(column => handleColumnVisibility(column.key, checkedItems.includes(column.key)));
    }, [columns, handleColumnVisibility]);

    return (
        <ModalFilter
            onSave={handleOnSave}
            items={columns}
            checkedItems={visibleColumns}
            defaultItems={defaultItems}
            labelButton={labelButton}
            labelPopover="Customize columns"
            dataTestId="column-management"
        />
    );
}
