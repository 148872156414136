import { EntityType } from '../lib/types';
import { gql, useQuery } from '@apollo/client';
import { useLocalStorageJSONWithTTL } from './useLocalStorage';
import { useAuth } from './useAuth';

const DEFAULT_ADVERTISER = gql`
    query fetchDefaultAdvertiser {
        defaultAdvertiser {
            id
            name
            account {
                id
                name
            }
        }
    }
`;

export const useDefaultAdvertiser = (
    skip: boolean,
): {
    advertiser: EntityType | undefined;
    account: EntityType | undefined;
    error: Error | undefined;
    loading: boolean;
} => {
    function unpack(obj: any): [EntityType | undefined, EntityType | undefined] {
        try {
            const account: EntityType = obj.account;
            const advertiser: EntityType = {
                id: obj.id,
                name: obj.name,
                parentId: account.id,
            };
            return [account, advertiser];
        } catch (e) {
            console.error('useDefaultAdvertiser: poisoned local storage', e);
            return [undefined, undefined];
        }
    }

    const { userId, accountId } = useAuth();
    const [advertiserLS, setAdvertiserLS] = useLocalStorageJSONWithTTL(`vaDefaultAdvertiser_${accountId}_${userId}`, 12 * 60 * 60 * 1000); // 12 hours
    const takeFromCache = advertiserLS != null && typeof advertiserLS === 'object';

    const { data, error, loading } = useQuery(DEFAULT_ADVERTISER, {
        skip: skip || takeFromCache,
    });

    if (!skip) {
        if (takeFromCache) {
            const [account, advertiser] = unpack(advertiserLS);
            return { advertiser, account, error: undefined, loading: false };
        } else if (data?.defaultAdvertiser) {
            setAdvertiserLS(data.defaultAdvertiser);
            const [account, advertiser] = unpack(data.defaultAdvertiser);
            return { advertiser, account, error, loading };
        }
    }

    return { advertiser: undefined, account: undefined, error, loading };
};
