import { useQuery } from '@apollo/client';
import { Dimension, Metric } from '../lib/types';
import { useDateRangeFilter, usePlacementTypesFilter } from './useFilters';

import TREE_MAP from '../graphql/cpd.treemap.gql';

export interface TreeMapDataPoint {
    dimension: string;
    metric: number;
}

export type MapDimension = (dimensionValue: string) => string;

export const useTreeMapQuery = (
    campaignId: number,
    metricName: Metric,
    dimesionName: Dimension,
    mapDimension: MapDimension,
): { loading: boolean; error: Error | undefined; treemap: TreeMapDataPoint[] | undefined } => {
    const { dateRange, timeZone } = useDateRangeFilter();
    const placementTypes = usePlacementTypesFilter();

    const { loading, error, data } = useQuery(TREE_MAP, {
        variables: {
            metric: metricName,
            dimension: dimesionName,
            campaignId,
            from: dateRange.from.toLocalTimestamp(),
            to: dateRange.to.toLocalTimestamp(),
            tzOffsetHours: timeZone.tzOffsetHours,
            filterByPlacementTypes: placementTypes,
        },
    });

    const treemap = data
        ?.treemap
        ?.map(({dimension, metric}: TreeMapDataPoint) => ({
            dimension: mapDimension(dimension),
            metric,
        }));

    return { loading, error, treemap };
};
