import gql from "graphql-tag";
import metrics from "./metrics.dto.gql";

const query = gql`
query fetchAdvertisersByAccountChart($from: Long!, $to: Long!, $tzOffsetHours: Int, $groupBy: ChartScaleType, $accountId: Long!) {
    chart: advertisersByAccountChart(from: $from, to: $to, tzOffsetHours: $tzOffsetHours, groupBy: $groupBy, accountId: $accountId) {
        epochSec
        ...metrics
    }
}
${metrics}
`;
export default query;
