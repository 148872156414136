import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './Layout.module.css';
import { useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import { DateRange } from '../components/DateRange';
import { PlacementFilter } from '../containers/PlacementFilter';
import { useDateRangeFilter, useSetDateRangeFilter } from '../hooks/useFilters';
import { EntityType, getKeyValue } from '../lib/types';
import { maybe } from '../lib/maybe';
import { utcFromUnixTime, DATE_FORMAT_FULL } from '../lib/formatters';
import { PageTitle } from './PageTitle';
import { verticalMappings } from '../lib/dimensions';

import ACCOUNT_METADATA from '../graphql/sas_account_metadata.gql';
import ADVERTISER_METADATA from '../graphql/sas_advertiser_metadata.gql';
import CAMPAIGN_SAS_METADATA from '../graphql/sas_campaign_metadata.gql';

function millisToDate(millis: number): DateTime {
    return utcFromUnixTime(millis / 1000);
}

const MetaValue: React.FC<{ title: string; value?: any }> = ({ title, value }) =>
    value ? (
        <span className={styles.metaValue} datatestid="page-meta">
            {title}: {value}
        </span>
    ) : null;

export interface AccountMetaProps {
    readonly entity: EntityType;
}

export const AccountMeta: React.FC<AccountMetaProps> = ({ entity }) => {
    const { data: sasMeta } = useQuery(ACCOUNT_METADATA, {
        variables: {
            accountId: entity.id,
        },
        skip: !entity.id,
    });
    const name = sasMeta?.sasAccountMetadata?.name ?? entity.name;
    const region = sasMeta?.sasAccountMetadata?.marketName;
    return (
        <>
            <PageTitle type="Account" name={name} />
            <div className={styles.meta}>
                <MetaValue title="ID" value={entity.id} />
                <MetaValue title="Region" value={region} />
            </div>
        </>
    );
};

export interface AdvertiserMetaProps {
    readonly entity: EntityType;
}

export const AdvertiserMeta: React.FC<AdvertiserMetaProps> = ({ entity }) => {
    const { data: sasMeta } = useQuery(ADVERTISER_METADATA, {
        variables: {
            advertiserId: entity.id,
        },
        skip: !entity.id,
    });
    const name = sasMeta?.sasAdvertiserMetadata?.name ?? entity.name;
    const verticalName = sasMeta?.sasAdvertiserMetadata?.verticalName;
    const verticalDisplayedName = getKeyValue<keyof typeof verticalMappings, typeof verticalMappings>(verticalName)(verticalMappings);
    return (
        <>
            <PageTitle type="Advertiser" name={name} />
            <div className={styles.meta}>
                <MetaValue title="ID" value={entity.id} />
                <MetaValue title="Vertical" value={verticalDisplayedName} />
            </div>
        </>
    );
};

export interface CampaignMetaProps {
    readonly entity: EntityType;
}

export const CampaignMeta: React.FC<CampaignMetaProps> = ({ entity }) => {
    const { data: sasMeta } = useQuery(CAMPAIGN_SAS_METADATA, {
        variables: {
            campaignId: entity.id,
        },
        skip: !entity.id,
    });
    const name = sasMeta?.sasCampaignMetadata?.name ?? entity.name;

    const meta = sasMeta?.sasCampaignMetadata;
    const startDate = maybe(
        meta?.startDate,
        (millis: number) => millisToDate(millis),
        (dt: DateTime) => dt.toFormat(DATE_FORMAT_FULL),
    );
    const endDate = maybe(
        meta?.endDate,
        (millis: number) => millisToDate(millis),
        (endDate: DateTime) => endDate.toFormat(DATE_FORMAT_FULL),
    );
    const daysLeft = maybe(
        meta?.endDate,
        (millis: number) => millisToDate(millis),
        (dt: DateTime) => dt.diff(DateTime.now(), 'days')?.days,
        (days: number) => Math.max(days, 0) | 0,
    );

    return (
        <>
            <PageTitle type="Campaign" name={name} />
            <div className={styles.meta}>
                {sasMeta?.sasCampaignMetadata?.status && (
                    <span className={styles.metaValue}>
                        Status:{' '}
                        <span className={classNames({ [styles.statusActive]: sasMeta?.sasCampaignMetadata?.status === 'Active' })}>
                            {sasMeta?.sasCampaignMetadata?.status}
                        </span>
                    </span>
                )}
                <MetaValue title="ID" value={entity.id} />
                <MetaValue title="Days left" value={daysLeft} />
                <MetaValue title="Start date" value={startDate} />
                <MetaValue title="End date" value={endDate} />
            </div>
            <div className={styles.filter}>
                <PlacementFilter />
            </div>
        </>
    );
};

export interface HeaderProps {
    readonly children?: ReactNode;
}

export const Header: React.FC<HeaderProps> = props => {
    const setDateRange = useSetDateRangeFilter();
    const { dateRange } = useDateRangeFilter();

    return (
        <header className={classNames(styles.header, styles.border)}>
            <div>{props.children}</div>
            <div className={styles.dateRange} datatestid={'date-picker'}>
                <DateRange range={dateRange} setDates={setDateRange} />
            </div>
        </header>
    );
};
