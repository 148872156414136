import gql from "graphql-tag";

const query = gql`
query fetchAdvertisersByAccount(
    $from: Long!
    $to: Long!
    $tzOffsetHours: Int
    $offset: Int!
    $limit: Int!
    $accountId: Long!
    $sortProperty: String
    $sortDirection: Direction
    $search: String
) {
    rows: advertisersByAccount(
        from: $from
        to: $to
        tzOffsetHours: $tzOffsetHours
        offset: $offset
        limit: $limit
        accountId: $accountId
        sortProperty: $sortProperty
        sortDirection: $sortDirection
        search: $search
    ) {
        advertiserId
        advertiserName
        verticalName
        totalCampaigns
        clicks
        conversions
        impressions
        interactions
        impressionsWithDwell
        impressionsWithVideoStart
        recordableImpressions
        ssaiImpressions
        ssaiClicks
        viewableImpressions
        videoStarted
        videoFullyPlayed
    }
    meta: advertisersByAccountTotalRows(from: $from, to: $to, accountId: $accountId, search: $search) {
        total
    }
}
`;
export default query;
