import moment from 'moment-timezone';

moment.updateLocale('en', {
    //TODO: user locale
    week: {
        dow: 1,
    },
});

function nowUTC() {
    return moment.utc();
}

export interface DateRangeSelection {
    period: string;
    label: string;
    start: moment.Moment;
    end: moment.Moment;
}

export enum PresetPeriod {
    today = 'TODAY',
    yesterday = 'YESTERDAY',
    last7Days = 'LAST_7_DAYS',
    thisWeek = 'THIS_WEEK',
    lastWeek = 'LAST_WEEK',
    last30Days = 'LAST_30_DAYS',
    lastMonth = 'LAST_MONTH',
    yearToDate = 'YEAR_TO_DATE',
    previous2DaysWithData = 'PREVIOUS_2_DAYS_WITH_DATA',
    previous2WeeksWithData = 'PREVIOUS_2_WEEKS_WITH_DATA',
}

export const presets: DateRangeSelection[] = [
    {
        label: 'Today',
        period: PresetPeriod.today,
        start: nowUTC().startOf('day'),
        end: nowUTC().endOf('day'),
    },
    {
        label: 'Yesterday',
        period: PresetPeriod.yesterday,
        start: nowUTC().subtract(1, 'days').startOf('day'),
        end: nowUTC().subtract(1, 'days').endOf('day'),
    },
    {
        label: 'Last 7 days',
        period: PresetPeriod.last7Days,
        start: nowUTC().subtract(7, 'days').startOf('day'),
        end: nowUTC().subtract(1, 'days').endOf('day'),
    },
    {
        label: 'This week',
        period: PresetPeriod.thisWeek,
        start: nowUTC().startOf('week'),
        end: nowUTC().endOf('day'),
    },
    {
        label: 'Last week',
        period: PresetPeriod.lastWeek,
        start: nowUTC().subtract(1, 'week').startOf('week'),
        end: nowUTC().subtract(1, 'week').endOf('week'),
    },
    {
        label: 'Last 30 days',
        period: PresetPeriod.last30Days,
        start: nowUTC().subtract(30, 'days').startOf('day'),
        end: nowUTC().subtract(1, 'days').endOf('day'),
    },
    {
        label: 'Last month',
        period: PresetPeriod.lastMonth,
        start: nowUTC().subtract(1, 'month').startOf('month'),
        end: nowUTC().subtract(1, 'month').endOf('month'),
    },
    {
        label: 'Year to date',
        period: PresetPeriod.yearToDate,
        start: nowUTC().startOf('year').startOf('day'),
        end: nowUTC().endOf('day'),
    },
];

export const uatPresets: DateRangeSelection[] = [...presets, 
    // This is a specific preset to be used by UI system tests. There is no data on UAT env so tests need have an ability to select some date range in the past for which data was present.
    {
        label: 'Previous 2 days with data',
        period: PresetPeriod.previous2DaysWithData,
        start: moment.utc('2020-09-30').subtract(2, 'days').startOf('day'),
        end: moment.utc('2020-09-30').subtract(1, 'days').endOf('day'),
    },
    {
        label: 'Previous 2 weeks with data',
        period: PresetPeriod.previous2WeeksWithData,
        start: moment.utc('2020-09-30').subtract(14, 'days').startOf('day'),
        end: moment.utc('2020-09-30').subtract(1, 'days').endOf('day'),
    }
];

