import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import styles from './HeatMap.module.css';
import { ChartTooltip } from '../Chart';
import { CPD_METRICS, Metric, TimeZoneDecriptor } from '../../lib/types';

export interface HeatMapItem {
    dayOfWeek: number;
    hourOfDay: number;
    metric: number;
}

export interface HeatMapProps {
    readonly data: HeatMapItem[];
    readonly metric: Metric;
    readonly timeZone: TimeZoneDecriptor;
}

const WEEKDAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const HOURS = Array.from({ length: 24 }).map((_v, i) => i);

function displayHour(hour: number): string {
    return ('' + hour).padStart(2, '0') + ':00';
}

interface HeatMapTooltipProps {
    readonly dayOfWeek: number;
    readonly hourOfDay: number;
    readonly timeZone: TimeZoneDecriptor;
    readonly metric: Metric;
    readonly value: number;
}

const HeatMapTooltip: React.FC<HeatMapTooltipProps> = ({ dayOfWeek, hourOfDay, timeZone, metric, value }) => (
    <div className={styles.heatmapTooltipWrap}>
        <ChartTooltip
            label={`${WEEKDAYS[dayOfWeek]}, ${displayHour(hourOfDay)} ${timeZone?.shortLabel}`}
            metrics={[
                {
                    metricName: CPD_METRICS[metric],
                    value,
                },
            ]}
        />
    </div>
);

const CELL_BG_EMPTY = '#F7F7F7';
const CELL_BG = (alpha: number) => `hsla(199,60%,45%,${alpha * 0.9 + 0.1})`;

interface HeatMapCellProps {
    readonly dayOfWeek: number;
    readonly hourOfDay: number;
    readonly timeZone: TimeZoneDecriptor;
    readonly metric: Metric;
    readonly value: number;
    readonly min: number;
    readonly max: number;
}

const HeatMapCell: React.FC<HeatMapCellProps> = ({ dayOfWeek, hourOfDay, timeZone, metric, min, max, value }) => {
    const [showToolTip, setShowToolTip] = useState<boolean>(false);
    const showTooltip = useCallback(() => setShowToolTip(true), [setShowToolTip]);
    const hideTooltip = useCallback(() => setShowToolTip(false), [setShowToolTip]);

    return (
        <div
            className={classNames(styles.heatMapCell, styles.heatMapCellValue)}
            style={{
                backgroundColor: value ? CELL_BG(Math.ceil(((value - min) / (max - min + 0.00001)) * 10) / 10) : CELL_BG_EMPTY,
            }}
            onMouseEnter={showTooltip}
            onMouseLeave={hideTooltip}>
            {showToolTip && (
                <HeatMapTooltip dayOfWeek={dayOfWeek} hourOfDay={hourOfDay} timeZone={timeZone} metric={metric} value={value} />
            )}
        </div>
    );
};

const DayOfWeek: React.FC<{ dayOfWeek: number }> = ({ dayOfWeek }) => (
    <div className={classNames(styles.heatMapCell, styles.heatMapDow)}>{WEEKDAYS[dayOfWeek].substring(0, 2)}</div>
);

const HourOfDay: React.FC<{ hourOfDay: number }> = ({ hourOfDay }) => (
    <div className={classNames(styles.heatMapCell, styles.heatMapHour)} style={{ gridColumn: `${hourOfDay + 1} / span 3` }}>
        <span>{displayHour(hourOfDay)}</span>
    </div>
);

export const HeatMap: React.FC<HeatMapProps> = ({ data, metric, timeZone }) => {
    const min = Math.min(...data.map(it => it.metric).filter(it => it !== 0)) ?? 0;
    const max = Math.max(...data.map(it => it.metric));

    const days = useMemo(() => Object.fromEntries(data.map(({ dayOfWeek, hourOfDay, metric }) => [dayOfWeek * 100 + hourOfDay, metric])), [data]);

    return (
        <div className={styles.heatMap}>
            {WEEKDAYS.flatMap((day, dayOfWeek) => (
                <React.Fragment key={dayOfWeek}>
                    <DayOfWeek dayOfWeek={dayOfWeek} />
                    {HOURS.map(hourOfDay => (
                        <HeatMapCell
                            key={hourOfDay}
                            dayOfWeek={dayOfWeek}
                            hourOfDay={hourOfDay}
                            timeZone={timeZone}
                            metric={metric}
                            min={min}
                            max={max}
                            value={days[dayOfWeek * 100 + hourOfDay] ?? 0}
                        />
                    ))}
                </React.Fragment>
            ))}
            {HOURS.map(hourOfDay => hourOfDay % 3 === 0 && <HourOfDay key={hourOfDay} hourOfDay={hourOfDay} />)}
        </div>
    );
};
