import React from 'react';
import styles from './Grid.module.css';
import { ColumnApi } from '@ag-grid-community/core';
import { GridColumns } from './GridColumns';
import { GridSearch } from './GridSearch';

export interface GridHeaderProps {
    readonly isCampaignGrid: boolean;
    readonly hiddenDimensions: string[];
    readonly hiddenMetrics: string[];
    readonly columnApi: ColumnApi;
    readonly setSearchQuery: (searchQuery: string | null) => void;
}

export const GridHeader: React.FC<GridHeaderProps> = props => {
    return (
        <div className={styles.gridHeader}>
            <GridSearch setSearchQuery={props.setSearchQuery} />
            <GridColumns
                columnApi={props.columnApi}
                isCampaignGrid={props.isCampaignGrid}
                hiddenDimensions={props.hiddenDimensions}
                hiddenMetrics={props.hiddenMetrics}
            />
        </div>
    );
};
