export const SET_PAGE_TITLE = 'SET_PAGE';
export interface AppState {
    pageTitle: string;
}

export interface SetPageTitleAction {
    type: typeof SET_PAGE_TITLE;
    pageTitle: string;
}

export const setPageTitle = (pageTitle: string): SetPageTitleAction => {
    return {
        type: SET_PAGE_TITLE,
        pageTitle: pageTitle,
    };
};

export type AppActionTypes = SetPageTitleAction;
