import gql from "graphql-tag";

const query = gql`
query fetchCampaignsByAdvertiser(
    $advertiserId: Long!
    $sortProperty: String
    $sortDirection: Direction
    $from: Long!
    $to: Long!
    $tzOffsetHours: Int
    $offset: Int!
    $limit: Int!
    $search: String
) {
    rows: campaignsByAdvertiser(
        advertiserId: $advertiserId
        sortProperty: $sortProperty
        sortDirection: $sortDirection
        from: $from
        to: $to
        tzOffsetHours: $tzOffsetHours
        offset: $offset
        limit: $limit
        search: $search
    ) {
        campaignId
        campaignName
        clicks
        conversions
        impressions
        interactions
        impressionsWithDwell
        impressionsWithVideoStart
        recordableImpressions
        ssaiImpressions
        ssaiClicks
        viewableImpressions
        videoStarted
        videoFullyPlayed
    }
    meta: campaignsByAdvertiserTotalRows(from: $from, to: $to, advertiserId: $advertiserId, search: $search) {
        total
    }
}
`;
export default query;
