import { InlineMessage } from '@amzn/storm-ui';
import React from 'react';
import styles from './Messages.module.css';

interface MsgErrorProps {
    e?: Error;
    msg?: string;
}
export const MsgError: React.FC<MsgErrorProps> = ({ e, msg }) => {
    e && console.error(e);
    return <InlineMessage className={styles.msgCenter} error message={msg || 'Sorry, something went wrong.'} />;
};
