import React from 'react';
import { RadioButton, RadioGroup } from '@amzn/storm-ui';
import { useRandomString } from '../../hooks/useRandomString';
import styles from './FeedbackContent.module.css';

const satisfactionLevelOptions: string[] = ['Satisfied', 'Neutral', 'Unsatisfied'];

export const defaultSatisfactionLevel = satisfactionLevelOptions[0];

interface SatisfactionLevelProps {
    readonly value: string;
    readonly onChange: (v: string) => void;
}

export const SatisfactionLevel: React.FC<SatisfactionLevelProps> = ({ value, onChange }) => {
    const id = useRandomString();
    return (
        <div className={styles.satisfactionLevel}>
            <label id={id}>Overall, are you satisfied with Visual Analytics experience?</label>
            <RadioGroup
                name={`satisfaction-level-${id}`}
                aria-labelledby={id}
                onChange={onChange}
                selectedValue={value || defaultSatisfactionLevel}
                inline>
                {satisfactionLevelOptions.map(s => (
                    <RadioButton key={s + id} id={id + s} label={s} value={s} />
                ))}
            </RadioGroup>
        </div>
    );
};
