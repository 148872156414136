import React from 'react';
import { Jumbo } from '../../containers/Jumbo';
import { METRICS } from '../../lib/types';

import ADEVERTISERS_CHART from '../../graphql/advertisers.chart.gql';
import ADEVERTISERS_METRICS from '../../graphql/advertisers.metrics.gql';

export interface AdvertisersJumboProps {
    readonly accountId: number;
}

export const AdvertisersJumbo: React.FC<AdvertisersJumboProps> = ({ accountId }) => {
    return <Jumbo
        queryChart={ADEVERTISERS_CHART}
        queryMetrics={ADEVERTISERS_METRICS}
        queryVars={{ accountId }}
        dictionary={METRICS}
        dataTestId="advertisers" />;
};
