import React, {useCallback, useState} from 'react';
// import {TextButton} from '@amzn/storm-ui';
import styles from './Grid.module.css';
import {FeedbackModal} from '../../components/FeedbackModal/FeedbackModal';

export interface GridFooterProps {
    readonly children?: React.ReactNode;
}

export const GridFooter: React.FC<GridFooterProps> = props => {
    const [isModalOpen, setModalOpen] = useState<boolean>(false);

    const closeModal = useCallback(() => {
        setModalOpen(false);
    }, [setModalOpen]);

    /*
    const openModal = useCallback(
        (e: Event) => {
            e.preventDefault();
            e.stopPropagation();
            setModalOpen(true);
        },
        [setModalOpen],
    );
    */

    return (
        <>
            <FeedbackModal isModalOpen={isModalOpen} onClose={closeModal} />
            <div className={styles.divider} />
            <div className={styles.gridFooter}>
                <div className={styles.info}>
                    {/* <TextButton onClick={openModal}>Feedback</TextButton> */}
                </div>
                {props.children}
            </div>
        </>
    );
};
