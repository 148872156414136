import React from 'react';
import { MsgError, Loading } from '../../components/Messages';
import { useHeatMapQuery } from '../../hooks/useHeatMapQuery';
import { Metric } from '../../lib/types';
import { HeatMap } from '../../components/HeatMap/HeatMap';
import { NoData } from '../../components/Chart/NoData';
import { Centered } from '../../components/Centered/Centered';
import { useDateRangeFilter } from '../../hooks/useFilters';

export interface HeatMapProps {
    readonly campaignId: number;
    readonly metric: Metric;
}

export const HeatMapContainer: React.FC<HeatMapProps> = ({ campaignId, metric }) => {
    const { dateRange, timeZone } = useDateRangeFilter();
    const { loading, error, heatmap } = useHeatMapQuery(metric, campaignId, dateRange, timeZone);
    if (error) {
        return (
            <Centered>
                <MsgError e={error} />
            </Centered>
        );
    } else if (loading) {
        return (
            <Centered>
                <Loading />
            </Centered>
        );
    } else if (heatmap && heatmap.length) {
        return <HeatMap data={heatmap} timeZone={timeZone} metric={metric} />;
    } else {
        return <NoData />;
    }
};
