import React from 'react';
import { ColDef, ICellRendererParams } from '@ag-grid-community/core';
import { Grid, columnTypes as ct } from '../../containers/Grid';
import { TwoRowsCellRenderer } from '../../containers/Grid/renders/cellRenders';
import { commonMetricColumns } from '../Common/grids';
import { mapPlacementType } from '../../lib/dimensions';
import { formatEpochGrid } from '../../lib/formatters';

import CAMPAIGN_GRID from '../../graphql/cpd.grid.gql';

const columnDefs: ColDef[] = [
    {
        field: 'epochSec',
        headerName: 'Date',
        type: [ct.isDefault, ct.isDimension, ct.text],
        minWidth: 120,
        cellRenderer: (params: ICellRendererParams) => formatEpochGrid(params.value)[0],
    },
    {
        field: 'siteName',
        headerName: 'Site',
        minWidth: 350,
        type: [ct.isDefault, ct.isDimension, ct.text],
        cellRenderer: (params: ICellRendererParams) => {
            const siteId = params.data?.siteId;
            if (siteId) {
                return (
                    <TwoRowsCellRenderer desc={siteId}>
                        {params.value || <span className="vaNotAvailable">Not available</span>}
                    </TwoRowsCellRenderer>
                );
            } else {
                return <span className="vaNotAvailable">Not available</span>;
            }
        },
    },
    {
        field: 'placementType',
        headerName: 'Placement type',
        minWidth: 190,
        type: [ct.isDefault, ct.isDimension, ct.text],
        cellRenderer: (params: ICellRendererParams) => {
            const mapped = mapPlacementType(params.value);
            return mapped || <span className="vaNotAvailable">Not available</span>;
        },
    },
    ...commonMetricColumns,
];

export interface CampaignGridProps {
    readonly campaignId: number;
}

export const CampaignGrid: React.FC<CampaignGridProps> = ({ campaignId }) => (
    <Grid query={CAMPAIGN_GRID} queryVars={{ campaignId }} columnDefs={columnDefs} isCampaignGrid dataTestId="campaigns-grid" />
);
