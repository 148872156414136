import React from 'react';
import classNames from 'classnames';
import { formatMetric } from '../../lib/formatters';
import styles from './Chart.module.css';

const LegendName: React.FC<{ children: string; color?: string }> = ({ children, color }) => (
    <span className={styles.legendName}>
        {color ? <span className={styles.legendMarker} style={{ borderColor: color }} /> : null}
        {children}
    </span>
);

const LegendValue: React.FC<{ children: string }> = ({ children }) => <span className={styles.legendValue}>{children}</span>;

interface LegendItem {
    readonly color?: string;
    readonly name: string;
    readonly value: string;
}

const Legend: React.FC<{ items: LegendItem[] }> = ({ items }) => (
    <div className={styles.legend}>
        {items.map(({ name, value, color }, index) => (
            <React.Fragment key={index}>
                <LegendName color={color}>{name}</LegendName>
                <LegendValue>{value}</LegendValue>
            </React.Fragment>
        ))}
    </div>
);

export interface ChartTooltipProps {
    label: string;
    metrics: {
        metric?: string;
        metricName: string;
        value: number;
        color?: string;
    }[];
}

export const ChartTooltip: React.FC<ChartTooltipProps> = ({ label, metrics }) => {
    const items = metrics.map(({ metric, metricName, value, color }) => ({
        color,
        name: metricName,
        value: formatMetric(value, metric),
    }));

    return (
        <div className={styles.chartToolTip}>
            <div className={classNames(styles.label, { [styles.notAvailable]: !label })}>{label || 'Not available'}</div>
            <Legend items={items} />
        </div>
    );
};
