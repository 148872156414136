import { useMemo } from 'react';
import { DocumentNode, useQuery } from '@apollo/client';
import { MetricValues, Metric, DateRangeType, allMetrics } from './../lib/types';
import { useDateRangeFilter, usePlacementTypesFilter } from './useFilters';
import { calculatePreviousDateRange } from '../lib/dateRange';

export interface MetricsResponse {
    loading: boolean;
    error: Error | undefined;
    metrics: Map<Metric, MetricValues> | undefined;
    currentRange: DateRangeType;
    previousRange: DateRangeType;
}

export const useMetrics = (query: DocumentNode, queryVars: Record<string, unknown>): MetricsResponse => {
    const { dateRange, timeZone } = useDateRangeFilter();
    const placementTypes = usePlacementTypesFilter();

    const prevDateRange: DateRangeType = calculatePreviousDateRange(dateRange);

    const { loading, error, data } = useQuery(query, {
        variables: {
            from: dateRange.from.toLocalTimestamp(),
            to: dateRange.to.toLocalTimestamp(),
            prevFrom: prevDateRange.from.toLocalTimestamp(),
            prevTo: prevDateRange.to.toLocalTimestamp(),
            tzOffsetHours: timeZone.tzOffsetHours,
            ...queryVars,
            filterByPlacementTypes: placementTypes,
            groupBy: null,
        },
    });

    const result = useMemo(() => {
        if (data) {
            const result = new Map<Metric, MetricValues>();
            allMetrics.forEach(({ field }) => {
                result.set(field, {
                    curr: data.curr?.[0]?.[field] ?? undefined,
                    prev: data.prev?.[0]?.[field] ?? undefined,
                });
            });
            return result;
        }
        return undefined;
    }, [data]);

    return { loading, error, metrics: result, currentRange: dateRange, previousRange: prevDateRange };
};
