import { Reducer } from 'redux';
import { DateRangeType, NaiveDateTime, PlacementTypes } from '../../lib/types';
import {
    SET_DATE_RANGE,
    DateRangeAction,
    PlacementTypesAction,
    SET_PLACEMENT_TYPES,
} from './actions';
import { DateTime } from 'luxon';

export function createInitDateRange(): DateRangeType {
    const nowUTC = DateTime.now().setZone('utc');
    return {
        from: NaiveDateTime.fromUTCDateTime(nowUTC.minus({ days: 14 }).startOf('day')),
        to: NaiveDateTime.fromUTCDateTime(nowUTC.minus({ hours: 1 }).endOf('day')),
    };
}

const initDateRange: DateRangeType = createInitDateRange();

const initPlacementTypes: PlacementTypes[] | null = null;

export const dateRangeReducer: Reducer<DateRangeType, DateRangeAction> = (state: DateRangeType = initDateRange, action: DateRangeAction) => {
    switch (action.type) {
        case SET_DATE_RANGE: {
            return { ...state, ...action.epochDateRange };
        }
        default: {
            return state;
        }
    }
};

export const placementTypesReducer: Reducer<PlacementTypes[] | null, PlacementTypesAction> = (
    state: PlacementTypes[] | null = initPlacementTypes,
    action: PlacementTypesAction,
) => {
    switch (action.type) {
        case SET_PLACEMENT_TYPES: {
            return action.placementTypes;
        }
        default: {
            return state;
        }
    }
};
