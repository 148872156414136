import { useRouteMatch } from 'react-router-dom';
import { EntityType } from '../lib/types';
import { useAccountQuery, useAdvertiserQuery, useCampaignQuery } from './useQueries';

export const useAccountParam = (): { account: EntityType | undefined; error: Error | undefined; loading: boolean } => {
    const accountURL = useRouteMatch<{ accountId: string }>({
        path: '/accounts/:accountId',
        exact: true,
    });
    const accountId = accountURL ? +accountURL.params.accountId : undefined; //todo: convert to number
    return useAccountQuery(accountId, !Number.isInteger(accountId));
};

export const useAdvertiserParam = (): {
    advertiser: EntityType | undefined;
    account: EntityType | undefined;
    error: Error | undefined;
    loading: boolean;
} => {
    const advertiserURL = useRouteMatch<{ advertiserId: string }>({
        path: '/advertisers/:advertiserId',
        exact: true,
    });
    const advertiserId = advertiserURL ? +advertiserURL.params.advertiserId : undefined; //todo: convert to number
    return useAdvertiserQuery(advertiserId, !Number.isInteger(advertiserId));
};

export const useCampaignParam = (): {
    campaign: EntityType | undefined;
    advertiser: EntityType | undefined;
    account: EntityType | undefined;
    error: Error | undefined;
    loading: boolean;
} => {
    const campaignURL = useRouteMatch<{ campaignId: string }>({
        path: '/campaigns/:campaignId',
        exact: true,
    });

    const campaignId = campaignURL ? +campaignURL.params.campaignId : undefined; //todo: convert to number
    return useCampaignQuery(campaignId, !Number.isInteger(campaignId));
};
