import { Reducer } from 'redux';
import { SET_PAGE_TITLE, AppState, AppActionTypes } from './actions';

const initialState: AppState = {
    pageTitle: 'All acounts',
};

export const pageTitleReducer: Reducer<AppState, AppActionTypes> = (state = initialState, action: AppActionTypes) => {
    switch (action.type) {
        case SET_PAGE_TITLE: {
            return { ...state, pageTitle: action.pageTitle };
        }
        default: {
            return state;
        }
    }
};
