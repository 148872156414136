const LOCAL_API_HOST = 'http://localhost:3000';

let parentHost = 'va-sizmek.advertising.amazon.dev';

try {
    parentHost = window.top!!.location.hostname;
} catch (e) {
    console.warn(`${(e as Error).message}; fallback host: ${parentHost}`, e);
}

const API_HOST = parentHost === 'localhost' ? LOCAL_API_HOST : `https://${parentHost}`;
const API_REST_URL = `${API_HOST}/spa/visual-analytics/rest`;
export const API_GRAPHQL_URL = `${API_HOST}/spa/visual-analytics/graphql`;

export const buildUrlApiRest = (url: string): string => `${API_REST_URL}${url}`;
