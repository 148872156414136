import React from 'react';
import { MsgError, Loading } from '../../components/Messages';
import { Dimension, Metric } from '../../lib/types';
import { mapDimension } from '../../lib/dimensions';
import { TreeMap } from '../../components/Chart';
import { useTreeMapQuery } from '../../hooks/useTreeMapQuery';
import { NoData } from '../../components/Chart/NoData';
import { Centered } from '../../components/Centered/Centered';

export interface TreeMapContainerProps {
    readonly campaignId: number;
    readonly metric: Metric;
    readonly dimension: Dimension;
}

export const TreeMapContainer: React.FC<TreeMapContainerProps> = ({ campaignId, metric, dimension }) => {
    const { loading, error, treemap } = useTreeMapQuery(campaignId, metric, dimension, d => mapDimension(dimension, d));
    if (error) {
        return (
            <Centered>
                <MsgError e={error} />
            </Centered>
        );
    } else if (loading) {
        return (
            <Centered>
                <Loading />
            </Centered>
        );
    } else if (treemap && treemap.length) {
        return <TreeMap data={treemap} metric={metric} dimension={dimension} />;
    } else {
        return <NoData />;
    }
};
