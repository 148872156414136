import React, { useState } from 'react';
import { Chart } from './Chart';
import styles from './Jumbo.module.css';
import { DocumentNode } from '@apollo/client';
import { Ticker } from '../../lib/tickers';
import { ChartGroupBy } from '../../components/GroupBy/ChartGroupBy';
import { useMetrics } from '../../hooks/useMetrics';
import { HeaderWidget, SideWidget } from '../../components/Widget';
import { NoData } from '../../components/Chart/NoData';
import { AppError, Loading } from '../../components/Messages';
import { Centered } from '../../components/Centered/Centered';
import { Metric, METRICS, CPD_METRICS } from '../../lib/types';

export interface JumboProps {
    readonly queryChart: DocumentNode;
    readonly queryMetrics: DocumentNode;
    readonly queryVars: Record<string, unknown>;
    readonly dictionary: typeof METRICS | typeof CPD_METRICS;
    readonly dataTestId: string;
}

export const Jumbo: React.FC<JumboProps> = props => {
    const [groupBy, setGroupBy] = useState<Ticker>(Ticker.days);
    const { loading, error, metrics, currentRange, previousRange } = useMetrics(props.queryMetrics, props.queryVars);

    const [leftLine, setLeftLine] = useState<Metric>('impressions');
    const [rightLine, setRightLine] = useState<Metric>('clicks');
    const [third, setThird] = useState<Metric>('interactions');

    if (loading) {
        return (
            <Centered>
                <Loading />
            </Centered>
        );
    }

    if (error) {
        return (
            <Centered>
                <AppError e={error} />
            </Centered>
        );
    }

    if (!metrics) {
        return (
            <Centered>
                <NoData />
            </Centered>
        );
    }

    return (
        <div className={styles.board}>
            <div className={styles.header} datatestid={`${props.dataTestId}-board`}>
                <HeaderWidget
                    selectedMetric={leftLine}
                    values={metrics}
                    dictionary={props.dictionary}
                    className={styles.leftColor}
                    setMetricType={setLeftLine}
                    dataTestId={'first'}
                />
                <HeaderWidget
                    selectedMetric={rightLine}
                    values={metrics}
                    dictionary={props.dictionary}
                    className={styles.rightColor}
                    setMetricType={setRightLine}
                    dataTestId={'second'}
                />
                <HeaderWidget
                    selectedMetric={third}
                    values={metrics}
                    dictionary={props.dictionary}
                    setMetricType={setThird}
                    dataTestId={'third'}
                />
                <ChartGroupBy groupBy={groupBy} setGroupBy={setGroupBy} datatestid="date-format-switch" />
            </div>
            <div className={styles.main}>
                <div className={styles.content} datatestid={`${props.dataTestId}-chart`}>
                    <Chart query={props.queryChart} queryVars={props.queryVars} groupBy={groupBy} leftLine={leftLine} rightLine={rightLine} />
                </div>
                <div className={styles.side}>
                    <SideWidget
                        selectedMetric={leftLine}
                        values={metrics}
                        dictionary={props.dictionary}
                        currentRange={currentRange}
                        previousRange={previousRange}
                        dataTestId={'first'}
                    />
                    <SideWidget
                        selectedMetric={rightLine}
                        values={metrics}
                        dictionary={props.dictionary}
                        currentRange={currentRange}
                        previousRange={previousRange}
                        dataTestId={'second'}
                    />
                    <SideWidget
                        selectedMetric={third}
                        values={metrics}
                        dictionary={props.dictionary}
                        currentRange={currentRange}
                        previousRange={previousRange}
                        dataTestId={'third'}
                    />
                </div>
            </div>
        </div>
    );
};
