import React from 'react';
import styles from './Layout.module.css';
import classNames from 'classnames';

export interface PanelProps {
    readonly className?: string;
    readonly border?: boolean;
    readonly children?: React.ReactNode;
}

export const Panel: React.FC<PanelProps> = props => {
    return <section className={classNames(props.className, styles.panel, { [styles.border]: props.border })}>{props.children}</section>;
};
