import React, { useState } from 'react';
import { Dropdown } from '../../components/Dropdown';
import { Dimension, Metric, METRICS } from '../../lib/types';
import { MiniBarsContainer } from '../../containers/MiniBars';
import { MiniChart } from '../../containers/MiniChart';
import { useLocalStorage } from '../../hooks/useLocalStorage';

let CPD_DIMENSIONS_MINI_BARS: any = {
    placementType: 'Placement type',
    environment: 'Environment',
    deviceType: 'Device type',
};

export interface CampaignMiniBarsProps {
    readonly campaignId: number;
}

export const CampaignMiniBars: React.FC<CampaignMiniBarsProps> = (props) => {
    const [hackathonFF] = useLocalStorage('hackathonFF');

    const [metric, setMetric] = useState<Metric>('impressions');
    const [dimension, setDimension] = useState<Dimension>('placementType');

    console.log(hackathonFF);

    if (hackathonFF === "on") {
        CPD_DIMENSIONS_MINI_BARS['city' as Dimension] = 'City' as any;
        CPD_DIMENSIONS_MINI_BARS['state' as Dimension] = 'State' as any;
        CPD_DIMENSIONS_MINI_BARS['country' as Dimension] = 'Country' as any;
    }

    return (
        <MiniChart header={<>
            <Dropdown
                selected={metric}
                dictionary={METRICS}
                onChange={setMetric} />
            <span>by</span>
            <Dropdown
                selected={dimension}
                dictionary={CPD_DIMENSIONS_MINI_BARS as any}
                onChange={setDimension} />
        </>} dataTestId="barChart">
            <MiniBarsContainer campaignId={props.campaignId} metric={metric} dimension={dimension} />
        </MiniChart >
    );
};
