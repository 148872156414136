import React from 'react';
import styles from './MiniChart.module.css';

export interface MiniChartProps {
    readonly header: React.ReactNode;
    readonly dataTestId: string;
    readonly children?: React.ReactNode;
}

export const MiniChart: React.FC<MiniChartProps> = ({ header, children, dataTestId }) => {
    return (
        <div className={styles.minichart} datatestid={dataTestId}>
            <div className={styles.header}>{header}</div>
            <div className={styles.content}>{children}</div>
        </div>
    );
};
