import React from 'react';
import { Jumbo } from '../../containers/Jumbo';
import { METRICS } from '../../lib/types';

import ACCOUNTS_CHART from '../../graphql/accounts.chart.gql';
import ACCOUNTS_METRICS from '../../graphql/accounts.metrics.gql';

export const AccountsJumbo: React.FC = () => {
    return <Jumbo
        queryChart={ACCOUNTS_CHART}
        queryMetrics={ACCOUNTS_METRICS}
        queryVars={{}}
        dictionary={METRICS}
        dataTestId="accounts" />;
};
