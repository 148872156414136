import React from 'react';
import { ResponsiveContainer, Tooltip, Treemap } from 'recharts';
import { CPD_METRICS, Dimension, Metric } from '../../lib/types';
import { formatYAxis } from '../../lib/formatters';
import { range } from '../../lib/range';
import { ChartTooltip } from './ChartTooltip';
import styles from './TreeMap.module.css';

export interface TreeMapItem {
    dimension: string;
    metric: number;
}

export interface TreeMapProps {
    readonly data: TreeMapItem[];
    readonly metric: Metric;
    readonly dimension: Dimension;
}

export const TreeMap: React.FC<TreeMapProps> = ({ data, metric }) => {
    //recharts treemap required field to be named `name`
    const data1 = data.map(it => ({
        name: it.dimension || '',
        value: it.metric,
    }));

    const min = Math.min(...data.map(it => it.metric).filter(it => it !== 0)) ?? 0;
    const max = Math.max(...data.map(it => it.metric));

    return (
        <div className={styles.container}>
            <ResponsiveContainer>
                <Treemap isAnimationActive={false} data={data1} aspectRatio={4 / 3} stroke="#fff" content={<Area min={min} max={max} />}>
                    <Tooltip content={<TreeMapTooltip metricName={CPD_METRICS[metric]} />} cursor={false} />
                </Treemap>
            </ResponsiveContainer>
        </div>
    );
};

const CELL_BG_EMPTY = '#F7F7F7';
const CELL_BG_PALETTE = range({ start: 45, step: 5, stop: 90 }).map(l => `hsl(199, 60%, ${l}%)`);

export const Area: React.FC<any> = (props: any) => {
    const { root, x, y, width, height, name, index, depth } = props;
    const data = root?.children?.find((c: any) => c?.name === name);
    const clipId = `cut-off-${index}`;

    if (depth !== 1) {
        return null;
    }

    const clipWidth = width - 14;
    const clipHeight = height - 14;

    const fill = data?.value ? CELL_BG_PALETTE[index] ?? CELL_BG_PALETTE[CELL_BG_PALETTE.length - 1] : CELL_BG_EMPTY;

    return (
        <g className={styles.area}>
            <defs>
                <clipPath id={clipId}>
                    <rect x={x + 7} y={y + 7} width={clipWidth} height={clipHeight} />
                </clipPath>
            </defs>
            <rect x={x} y={y} width={width - 1} height={height - 1} className={styles.rect} fill={fill} />
            <text x={x + 8} y={y + 21} fill="#fff" fontSize={13} fontWeight="bold" clipPath={`url(#${clipId})`} className={styles.text}>
                {clipWidth >= 20 && clipHeight >= 13 && (
                    <tspan x={x + 8} fontStyle={name ? 'normal' : 'italic'}>
                        {name || 'Not available'}
                    </tspan>
                )}
                {data && clipWidth >= 20 && clipHeight >= 27 && (
                    <tspan x={x + 8} dy="14">
                        {formatYAxis(data.value)}
                    </tspan>
                )}
            </text>
        </g>
    );
};

const TreeMapTooltip: React.FC<any> = (props: any) => {
    const { metricName } = props;
    if (props.active && props.payload && props.payload.length) {
        return (
            <ChartTooltip
                label={props.payload[0].payload.name}
                metrics={[
                    {
                        metricName,
                        value: props.payload[0].value,
                    },
                ]}
            />
        );
    } else {
        return null;
    }
};
