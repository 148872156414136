import React from 'react';
import styles from './Centered.module.css';

export interface CenteredProps {
    readonly children?: React.ReactNode;
}

export const Centered: React.FC<CenteredProps> = ({children}) => (
    <div className={styles.container}>
        {children}
    </div>
);
