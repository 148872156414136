import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { formatYAxis } from '../../lib/formatters';
import { CPD_METRICS, Dimension, Metric } from '../../lib/types';
import styles from './Chart.module.css';
import { ChartTooltip } from './ChartTooltip';

export interface BarsItem {
    readonly dimension: string;
    readonly metric: number;
}

export interface MiniBarsProps {
    readonly data: BarsItem[];
    readonly metric: Metric;
    readonly dimension: Dimension;
}

export const MiniBars: React.FC<MiniBarsProps> = ({ data, metric }) => {
    const MiniBarsTooltip = useMemo(
        () => (props: any) => {
            if (props.active && props.payload && props.payload.length) {
                return (
                    <ChartTooltip
                        label={props.label}
                        metrics={[
                            {
                                metricName: CPD_METRICS[metric],
                                value: props.payload[0].value,
                            },
                        ]}
                    />
                );
            } else {
                return null;
            }
        },
        [metric],
    );

    const handleOnMouseEnter = useCallback((data: unknown, index: unknown, event: ChangeEvent<HTMLElement>) => {
        event.target.classList.add(styles.rectOnHover);
    }, []);

    const handleOnMouseLeave = useCallback((data: unknown, index: unknown, event: ChangeEvent<HTMLElement>) => {
        event.target.classList.remove(styles.rectOnHover);
    }, []);

    const formatCategoryAxis = (category: string | undefined): string => category || 'Not available';

    const categoryAxisWidth = data.map(item => formatCategoryAxis(item.dimension)).reduce((acc, category) => Math.max(acc, category.length * 7), 30);

    return (
        <div className={styles.miniBars}>
            <ResponsiveContainer>
                <BarChart data={data} layout="vertical" margin={{ top: 0, right: 15, bottom: 6, left: 5 }}>
                    <CartesianGrid horizontal={false} stroke={'#ececec'} />
                    <XAxis dataKey="metric" type="number" axisLine={false} tickLine={false} tickFormatter={formatYAxis} height={13} />
                    <YAxis
                        yAxisId="left"
                        dataKey="dimension"
                        width={categoryAxisWidth}
                        type="category"
                        interval={0}
                        tickLine={false}
                        tickSize={9}
                        tickFormatter={formatCategoryAxis}
                    />
                    <Tooltip content={<MiniBarsTooltip />} cursor={false} />
                    <Bar
                        yAxisId="left"
                        dataKey="metric"
                        fill="#2E8CB8"
                        isAnimationActive={true}
                        animationDuration={650}
                        onMouseEnter={handleOnMouseEnter}
                        onMouseLeave={handleOnMouseLeave}
                    />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};
