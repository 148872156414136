export const useLocalStorage = (key: string): [string | null, (value: string) => void] => {
    const setValue = (value: string): void => {
        window.localStorage.setItem(key, value);
    };
    const value = window.localStorage.getItem(key);
    return [value, setValue];
};

export const useLocalStorageJSON = (key: string): [any | null, (value: any | null) => void] => {
    const [value, setValue] = useLocalStorage(key);

    const setAsString = (value: any | null): void => {
        setValue(JSON.stringify(value));
    };

    function tryParseJSON(src: string | null): any | null {
        try {
            return src != null ? JSON.parse(src) : null;
        } catch (e) {
            console.error('useLocalStorageJSON: poisoned local storage', e);
            return null;
        }
    }

    let parsedValue = tryParseJSON(value);
    return [parsedValue, setAsString];
};

function now(): number {
    return new Date().getTime();
}

export const useLocalStorageJSONWithTTL = (key: string, maxAgeMillis: number): [any | null, (value: any | null) => void] => {
    const [value, setValue] = useLocalStorageJSON(key);

    const setWithTimestamp = (value: any | null): void => {
        setValue(value != null ? {ts: now(), value} : null);
    };

    let v = value?.ts && now() - value.ts < maxAgeMillis ? value.value : null;
    return [v, setWithTimestamp]
};
