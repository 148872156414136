import gql from "graphql-tag";

const fragment = gql`
fragment cpdMetrics on ChartCalculatedMetricRDTO {
    clicks
    conversions
    ctr
    cvr
    dwellRate
    impressions
    impressionsWithDwell
    impressionsWithVideoStart
    interactionRate
    interactions
    recordableImpressions
    recordableImpressionsRate
    ssaiClicks
    ssaiImpressions
    videoFullyPlayed
    videoFullyPlayedRate
    videoStarted
    videoStartedRate
    videoPlayed25
    videoPlayed50
    videoPlayed75
    avgVideoPercentageComplete
    viewableImpressions
    viewableImpressionsRate
}
`;
export default fragment;
