import gql from "graphql-tag";

const fragment = gql`
fragment metrics on ChartMetricRDTO {
    clicks
    conversions
    impressions
    interactions
    impressionsWithDwell
    impressionsWithVideoStart
    recordableImpressions
    ssaiImpressions
    ssaiClicks
    viewableImpressions
    videoStarted
    videoPlayed25
    videoPlayed50
    videoPlayed75
    videoFullyPlayed
}
`;
export default fragment;
