import React from 'react';
import { ColDef, ICellRendererParams } from '@ag-grid-community/core';
import { Grid, columnTypes as ct } from '../../containers/Grid';
import { AccountLink } from '../../components/PageLink';
import { TwoRowsCellRenderer } from '../../containers/Grid/renders/cellRenders';
import { commonMetricColumns, typesOfColumn } from '../Common/grids';

import ACCOUNTS_GRID from '../../graphql/accounts.grid.gql';

const metricColumnDefs = commonMetricColumns.filter(col => !typesOfColumn(col).includes(ct.isCPDOnly));

//cellRenderer: 'loadingRenderer',
const columnDefs: ColDef[] = [
    {
        headerName: 'Account',
        field: 'accountName',
        type: [ct.isDefault, ct.isDimension, ct.text],
        // headerTooltip: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Doloribus accusamus est veritatis.',
        cellRenderer: (params: ICellRendererParams) => {
            return (
                <TwoRowsCellRenderer desc={params.data?.accountId}>
                    {params.data && <AccountLink account={{ id: params.data.accountId, name: params.value }} />}
                </TwoRowsCellRenderer>
            );
        },
        minWidth: 450,
    },
    {
        field: 'marketName',
        headerName: 'Region',
        minWidth: 150,
        type: [ct.isDefault, ct.isDimension, ct.text],
        cellRenderer: (params: ICellRendererParams) => {
            return params.value || <span className="vaNotAvailable">Not available</span>;
        },
    },
    // {
    //     headerName: 'Advertisers',
    //     field: 'totalAdvertisers',
    //     type: [ct.isDefault, ct.isPinned, ct.numeric],
    //     sortable: false,
    // },
    ...metricColumnDefs,
];

export const AccountsGrid: React.FC = () => (
    <Grid query={ACCOUNTS_GRID} queryVars={{}} columnDefs={columnDefs} dataTestId="account-grid" />
);
