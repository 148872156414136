import gql from "graphql-tag";
import metrics from "./metrics.dto.gql";

const query = gql`
query fetchCampaignsChart($from: Long!, $to: Long!, $tzOffsetHours: Int, $advertiserId: Long!, $groupBy: ChartScaleType) {
    chart: campaignsChart(from: $from, to: $to, tzOffsetHours: $tzOffsetHours, advertiserId: $advertiserId, groupBy: $groupBy) {
        epochSec
        ...metrics
    }
}
${metrics}
`;
export default query;
