import gql from "graphql-tag";
import metrics from "./metrics.dto.gql";

const query = gql`
query fetchAccountsChart($from: Long!, $to: Long!, $tzOffsetHours: Int, $groupBy: ChartScaleType) {
    chart: accountsChart(from: $from, to: $to, tzOffsetHours: $tzOffsetHours, groupBy: $groupBy) {
        epochSec
        ...metrics
    }
}
${metrics}
`;
export default query;
