import React from 'react';
import classNames from 'classnames';
import { Alert } from '@amzn/storm-ui';
import { ApolloError } from '@apollo/client';
import { AccountsLink } from './../PageLink';
import styles from './Messages.module.css';

interface MsgBoxProps {
    readonly center?: boolean;
    readonly children?: React.ReactNode;
}

const MsgBox: React.FC<MsgBoxProps> = ({ center, children }) => (
    <div className={classNames(styles.msgBox, { [styles.msgCenter]: center })}>{children}</div>
);

const Reload: React.FC = () => (
    <div className={styles.action}>
        <AccountsLink label="Reload" />
        <span>the page and try again.</span>
    </div>
);

interface AppErrorProps {
    readonly e?: Error;
}

function extractMessages(e: Error): string[] {
    if (e instanceof ApolloError) {
        let messages: string[] = [];
        for (const ge of e.graphQLErrors) {
            if (!messages.includes(ge.message)) {
                messages.push(ge.message);
            }
        }
        return messages;
    }
    if (e.message) {
        return [e.message];
    }
    return [];
}

export const AppError: React.FC<AppErrorProps> = ({ e }) => {
    const messages = e ? extractMessages(e) : [];
    const accessDenied = messages.includes('Access denied');
    const header = accessDenied ? 'You do not have a permission to use this service' : 'Sorry, something went wrong.';
    const reload = !accessDenied;

    return (
        <MsgBox>
            <Alert type="error" header={header}>
                {messages.map(message => (
                    <div key={message}>{message}</div>
                ))}
                {reload ? <Reload /> : null}
            </Alert>
        </MsgBox>
    );
};

interface AppWarningProps {
    msg: string;
}

export const AppWarning: React.FC<AppWarningProps> = ({ msg }) => (
    <MsgBox center>
        <Alert type="warning" header={msg}>
            <Reload />
        </Alert>
    </MsgBox>
);
