import gql from "graphql-tag";
import metrics from "./metrics.dto.gql";

const query = gql`
query fetchCampaignsMetrics(
    $prevFrom: Long!
    $prevTo: Long!
    $from: Long!
    $to: Long!
    $tzOffsetHours: Int
    $advertiserId: Long!
    $groupBy: ChartScaleType
) {
    prev: campaignsChart(from: $prevFrom, to: $prevTo, tzOffsetHours: $tzOffsetHours, groupBy: $groupBy, advertiserId: $advertiserId) {
        epochSec
        ...metrics
    }
    curr: campaignsChart(from: $from, to: $to, tzOffsetHours: $tzOffsetHours, groupBy: $groupBy, advertiserId: $advertiserId) {
        epochSec
        ...metrics
    }
}
${metrics}
`;
export default query;
