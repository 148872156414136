import React, { ChangeEvent, useCallback } from 'react';
import { TextAreaFormGroup } from '@amzn/storm-ui';
import { useRandomString } from '../../hooks/useRandomString';
import styles from './FeedbackContent.module.css';

interface TextAreaProps {
    readonly text: string;
    readonly errorMessage?: string;
    readonly onChange: (v: string) => void;
}

export const ValidatedTextArea: React.FC<TextAreaProps> = ({ onChange, text, errorMessage }) => {
    const textAreaId = useRandomString();

    const handleTextChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            onChange(e.target.value);
        },
        [onChange],
    );

    return (
        <TextAreaFormGroup
            className={styles.textArea}
            label={''}
            id={textAreaId}
            placeholder="Enter your message here."
            fullWidth
            value={text}
            onChange={handleTextChange}
            error={errorMessage != null}
            message={errorMessage}
        />
    );
};
