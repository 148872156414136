import React, { useCallback, useEffect, useState } from 'react';
import { Button, Icon, TextButton } from '@amzn/storm-ui';
import { defaultFeedback, Feedback, FeedbackContent } from './FeedbackContent';
import { postFeedback } from '../../lib/api-request';
import classNames from 'classnames';
import { GenericFeedbackModal } from './GenericFeedbackModal';
import { FeedbackAlert } from './FeedbackAlert';
import styles from './FeedbackContent.module.css';
import { useLocalStorageJSON } from '../../hooks/useLocalStorage';

interface FeedbackModalProps {
    readonly isModalOpen: boolean;
    readonly onClose: () => void;
}

enum ViewState {
    INITIAL,
    SUBMIT,
    SUCCESS,
    ERROR,
}

const isFeedbackValid = (feedback: Feedback) => feedback.feedbackMessage.trim().length !== 0;

export const FeedbackModal: React.FC<FeedbackModalProps> = ({ isModalOpen, onClose }) => {
    const [feedback, setFeedback] = useState<Feedback>(defaultFeedback);
    const [viewState, setViewState] = useState<ViewState>(ViewState.INITIAL);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [user] = useLocalStorageJSON('user');
    const [disable, setDisable] = React.useState(false);

    let content;
    let footer = <EmptyFooter />;

    useEffect(() => {
        if (isModalOpen) {
            const wasSuccess = viewState === ViewState.SUCCESS;
            setViewState(ViewState.INITIAL);
            setErrorMessage(undefined);
            if (wasSuccess) {
                setFeedback(defaultFeedback);
            }
        }
    }, [isModalOpen, viewState, setViewState, setFeedback]);

    const updateFeedback = useCallback(
        (feedback: Feedback) => {
            setFeedback(feedback);
            if (isFeedbackValid(feedback)) {
                setErrorMessage(undefined);
            }
        },
        [setFeedback, setErrorMessage],
    );

    const submit = useCallback(() => {
        if (isFeedbackValid(feedback)) {
            setErrorMessage(undefined);
            setViewState(ViewState.SUBMIT);
            setDisable(true);
            postFeedback(feedback, user?.id).then(response => {
                response.ok ? setViewState(ViewState.SUCCESS) : setViewState(ViewState.ERROR);
                setDisable(false);
            });
        } else {
            setErrorMessage('This field is required.');
        }
    }, [feedback, user, setErrorMessage]);

    switch (viewState) {
        case ViewState.INITIAL:
            content = <FeedbackContent value={feedback} onChange={updateFeedback} errorMessage={errorMessage} />;
            footer = <Footer disabled={disable} onSubmit={submit} onClose={onClose} />;
            break;
        case ViewState.SUBMIT:
            content = <Icon type="spinner" className={styles.waitSpinner} size="3x" />;
            break;
        case ViewState.SUCCESS:
            content = <FeedbackAlert alertType="success" text="Your feedback has been received" onClose={onClose} />;
            break;
        case ViewState.ERROR:
        default:
            content = <FeedbackAlert alertType="error" text="Sorry, something went wrong." onClose={onClose} />;
    }

    return (
        <GenericFeedbackModal isModalOpen={isModalOpen} onClose={onClose} footer={footer}>
            {content}
        </GenericFeedbackModal>
    );
};

interface FooterProps {
    readonly disabled: boolean,
    readonly onClose: () => void;
    readonly onSubmit: () => void;
}

const Footer: React.FC<FooterProps> = ({ disabled, onClose, onSubmit }) => {
    return (
      <>
          <TextButton className={classNames(styles.cancelButton)} value="cancel" onClick={onClose}>
              Cancel
          </TextButton>
          <Button primary value="save" disabled={disabled} onClick={onSubmit}>
              Submit feedback
          </Button>
      </>
    )
};

const EmptyFooter: React.FC = () => (
    <TextButton style={{ visibility: 'hidden' }} value="">
        &nbsp
    </TextButton>
);
