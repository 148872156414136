import { ColDef } from '@ag-grid-community/core';
import { columnTypes as ct } from '../../containers/Grid';
import { allMetrics, MetricFormat, MetricInfo } from '../../lib/types';

function metricColumn(metric: MetricInfo, sort: boolean): ColDef {
    const type = [ct.numeric];
    if (metric.defaultOnPages.length > 0) {
        type.push(ct.isDefault);
    }
    if (metric.availableOnPages.length < 4) {
        type.push(ct.isCPDOnly);
    }
    if (metric.format === MetricFormat.COUNT) {
        type.push(ct.numericCounter);
    } else {
        // MetricFormat.RATE
        type.push(ct.numericPercent);
    }
    return {
        field: metric.field,
        headerName: metric.title,
        initialSort: sort ? 'desc' : undefined,
        type,
        minWidth: Math.max(85, metric.title.length * 9),
    };
}

export const commonMetricColumns: ColDef[] = allMetrics.map((mi, index) => metricColumn(mi, index === 0));

export function typesOfColumn(col: ColDef): string[] {
    if (!col.type) {
        return [];
    } else if (Array.isArray(col.type)) {
        return col.type;
    } else {
        return [col.type];
    }
}
