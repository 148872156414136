export interface RangeParams {
    start: number;
    stop?: number;
    stopInclusive?: number;
    step?: number;
}

export function range(params: RangeParams): number[] {
    let step = params?.step ?? 1;
    let check;
    const stop = params.stopInclusive ?? params.stop ?? params.start;
    const inclusive = params.stopInclusive;
    if (params.start <= stop) {
        step = Math.abs(step);
        if (inclusive) {
            check = (c: number) => c <= stop;
        } else {
            check = (c: number) => c < stop;
        }
    } else {
        step = -Math.abs(step);
        if (inclusive) {
            check = (c: number) => c >= stop;
        } else {
            check = (c: number) => c > stop;
        }
    }

    const result = [];
    for (let value = params.start; check(value); value += step) {
        result.push(value);
    }
    return result;
}
