import { useQuery } from '@apollo/client';
import { DateRangeType, TimeZoneDecriptor } from '../lib/types';
import { usePlacementTypesFilter } from './useFilters';

import HEAT_MAP from '../graphql/cpd.heatmap.gql';

export const useHeatMapQuery = (
    metricName: string,
    campaignId: number,
    dateRange: DateRangeType,
    timeZone: TimeZoneDecriptor,
): { loading: boolean; error: Error | undefined; heatmap: [{ dayOfWeek: number; hourOfDay: number; metric: number }] | undefined } => {
    const placementTypes = usePlacementTypesFilter();

    const { loading, error, data } = useQuery(HEAT_MAP, {
        variables: {
            metric: metricName,
            campaignId,
            from: dateRange.from.toLocalTimestamp(),
            to: dateRange.to.toLocalTimestamp(),
            tzOffsetHours: timeZone.tzOffsetHours,
            filterByPlacementTypes: placementTypes,
        },
    });

    return { loading, error, heatmap: data?.heatmap };
};
