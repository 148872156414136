import { useQuery } from '@apollo/client';
import { EntityType } from '../lib/types';
import ACCOUNT_SAS_METADATA from '../graphql/sas_account_metadata.gql';
import ADVERTISER_ACCOUNT_SAS_METADATA from '../graphql/sas_advertiser_account_metadata.gql';
import CAMPAIGN_ADVERTISER_SAS_METADATA from '../graphql/sas_campaign_advertiser_metadata.gql';

export const useAccountQuery = (
    accountId: number | undefined,
    skip: boolean = false,
): { loading: boolean; error: Error | undefined; account: EntityType | undefined } => {
    const { loading, error, data } = useQuery(ACCOUNT_SAS_METADATA, {
        variables: {
            accountId,
        },
        skip,
    });
    const id = data?.sasAccountMetadata?.id ?? accountId;
    return {
        loading,
        error,
        account: id ? { id, name: data?.sasAccountMetadata?.name } : undefined,
    };
};

export const useAdvertiserQuery = (
    advertiserId: number | undefined,
    skip: boolean = false,
): { loading: boolean; error: Error | undefined; account: EntityType | undefined; advertiser: EntityType | undefined } => {

    const { loading, error, data } = useQuery(ADVERTISER_ACCOUNT_SAS_METADATA, {
        variables: {
            advertiserId,
        },
        skip,
    });

    const id = data?.advertiser?.id ?? advertiserId;
    return {
        loading,
        error,
        advertiser: id
            ? {
                id,
                name: data?.advertiser?.name,
                parentId: data?.advertiser?.account?.id,
            }
            : undefined,
        account: data?.advertiser?.account,
    };
};

export const useCampaignQuery = (
    campaignId: number | undefined,
    skip: boolean = false,
): {
    loading: boolean;
    error: Error | undefined;
    campaign: EntityType | undefined;
    advertiser: EntityType | undefined;
    account: EntityType | undefined;
} => {
    const { loading, error, data } = useQuery(CAMPAIGN_ADVERTISER_SAS_METADATA, {
        variables: {
            campaignId,
        },
        skip,
    });

    const id = data?.campaign?.id ?? campaignId;
    const advertiser = data?.campaign?.advertiser;
    const account = advertiser?.account;

    return {
        loading: loading,
        error: error,
        campaign: id ? { id, name: data?.campaign?.name } : undefined,
        advertiser: advertiser?.id ? { id: advertiser.id, name: advertiser.name, parentId: account?.id } : undefined,
        account: account?.id ? { id: account.id, name: account.name } : undefined,
    };
};
