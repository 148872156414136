import React from 'react';
import { Dashboard } from '../../layouts';
import { AccountsGrid } from './AccountsGrid';
import { AccountsJumbo } from './AccountsJumbo';
import { PageTitle } from '../../layouts/PageTitle';

export const AccountsPage: React.FC = () => {
    return (
        <Dashboard
            breadcrumbs={[
                <>All accounts</>,
            ]}
            header={<PageTitle name="All accounts" />}
            jumbo={<AccountsJumbo/>}
            grid={<AccountsGrid/>}
        />
    );
};
