import React from 'react';
import { EntityType } from '../../lib/types';
import { EntityLink } from './EntityLink';

export interface CampaignLinkProps {
    readonly campaign: EntityType;
    readonly withId?: boolean;
    readonly withType?: boolean;
}

export const CampaignLink: React.FC<CampaignLinkProps> = props => (
    <>
        {props.withType && <span>Campaign:&nbsp;</span>}
        <EntityLink to={`/campaigns/${props.campaign.id}`} title={props.campaign.name}>
            {props.campaign.name}
            {props.withId && <span>&nbsp;({props.campaign.id})</span>}
        </EntityLink>
    </>
);
