import React from 'react';
import style from './Breadcrumbs.module.css';
import { BreadcrumbGroup, Breadcrumb } from '@amzn/storm-ui';

export interface BreadcrumbsPropsType {
    breadcrumbs: JSX.Element[];
}

export const Breadcrumbs: React.FC<BreadcrumbsPropsType> = (props: BreadcrumbsPropsType) => {

    const children = props.breadcrumbs.map((child, index) =>
        <Breadcrumb key={`item${index}`}>{child}</Breadcrumb>
    );

    return <BreadcrumbGroup className={style.breadcrumbs}>{children}</BreadcrumbGroup>
};