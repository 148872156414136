import React, { useState, useRef, MouseEvent, useCallback } from 'react';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { Icon, Popover, Button, SecondaryView, useIsMobile, trapTabFocus, keyboardKeyNames } from '@amzn/storm-ui';

import { DateRangePicker } from '@amzn/storm-ui-date-picker';
import { DateRangeSelection, presets, uatPresets } from './presets';
import { formatDateRange } from '../../lib/formatters';
import { DateRangeType, getTimezoneById, NaiveDateTime, TimeZoneId } from '../../lib/types';
import { TimezomePicker } from './TimezonePicker';
import { useLocalStorage } from '../../hooks/useLocalStorage';

const ButtonIcon = styled(Icon)`
    margin-right: ${({ theme }) => theme.spacing.small};
`;
ButtonIcon.displayName = 'ButtonIcon';

const StyledSecondaryView = styled(SecondaryView)`
    background: ${({ theme }) => theme.palette.white};
    z-index: 2;
`;

StyledSecondaryView.displayName = 'StyledSecondaryView';

const customPeriod = { id: 'CUSTOM', name: 'Date range' };

const getDateRangeType = (selection: DateRangeSelection): DateRangeType => ({
    from: NaiveDateTime.fromMoment(selection.start),
    to: NaiveDateTime.fromMoment(selection.end),
    period: selection.period,
});

export interface DateRangeProps {
    readonly range: DateRangeType;
    readonly setDates: (range: DateRangeType) => void;
}

export const DateRange: React.FC<DateRangeProps> = ({ range, setDates }) => {
    const [active, setActive] = useState<boolean>(false);
    const [selected, setSelected] = useState<DateRangeSelection>({
        label: customPeriod.name,
        period: customPeriod.id,
        start: range.from.toMoment(),
        end: range.to.toMoment(),
    });
    const [showFocus, setShowFocus] = useState(false);
    const isMobile = useIsMobile();
    const [dateRangeWithDataFF] = useLocalStorage('dateRangeWithDataForUiTestsOnUAT');

    const buttonRef = useRef<any>(null);
    const popoverRef = useRef<any>(null);

    const handleOnDatesChange = useCallback(() => {}, []);

    const handleShowFocus = useCallback((event: MouseEvent) => {
        // Check if click is triggered by mouse or keyboard
        // then decide if need to show focus
        if (event && event.detail) setShowFocus(false);
        else setShowFocus(true);
    }, []);

    const handleToggle = useCallback(
        (event: MouseEvent) => {
            handleShowFocus(event);
            setActive(prevState => !prevState);
        },
        [handleShowFocus],
    );

    // eslint-disable-next-line no-shadow
    const handleOnSubmit = useCallback(
        (event: MouseEvent, selected: any) => {
            selected = {
                ...selected,
                start: NaiveDateTime.fromMoment(selected.start).startOfDay().toMoment(),
                end: NaiveDateTime.fromMoment(selected.end).endOfDay().toMoment(),
            };
            setSelected(selected);
            setDates(getDateRangeType(selected));
            handleToggle(event);
        },
        [handleToggle, setDates],
    );

    const handleOnCancel = useCallback(
        (event: MouseEvent) => {
            handleToggle(event);
        },
        [handleToggle],
    );

    const handleKeyDownPopover = useCallback(
        (event: any) => {
            const { key } = event;
            if (popoverRef?.current?.wrapperRef) {
                trapTabFocus(event, popoverRef.current.wrapperRef);
            }

            if (key === keyboardKeyNames.ESCAPE) {
                handleOnCancel(event);
                event.preventDefault();
            }
        },
        [popoverRef, handleOnCancel],
    );

    const handleChangeTimezone = useCallback(
        (timeZoneId: TimeZoneId) => {
            setDates({ ...range, timeZoneId });
        },
        [range, setDates],
    );

    const getSelectedDateText = useCallback((label: string, selected: DateRangeSelection) => {
        return {
            dateRangePrefix: label ? selected.label : customPeriod.name,
            dateRangeLabel: formatDateRange(getDateRangeType(selected)),
        };
    }, []);

    const getDropdownLabel = useCallback(
        (label: string) => {
            const { dateRangePrefix, dateRangeLabel } = getSelectedDateText(label, selected);
            return (
                <>
                    <strong>{dateRangePrefix}:</strong>&nbsp;&nbsp;{dateRangeLabel}
                </>
            );
        },
        [selected, getSelectedDateText],
    );

    const getButtonText = useCallback(
        (selected: DateRangeSelection) => {
            const { dateRangePrefix, dateRangeLabel } = getSelectedDateText(selected.label, selected);
            return (
                <>
                    {dateRangePrefix}:&nbsp;{dateRangeLabel}
                </>
            );
        },
        [getSelectedDateText],
    );

    const getButtonRef = useCallback((element: any) => {
        buttonRef.current = element;
    }, []);

    const renderDateRangePicker = (dateRangeWithDataFF: string | null) => {
        const extra = { minimumNights: 0 };
        return (
            <DateRangePicker
                selected={selected}
                initialStartDate={selected.start}
                initialEndDate={selected.end}
                minDate={moment().subtract(3, 'year')}
                maxDate={moment().add(1, 'year')}
                onDatesChange={handleOnDatesChange}
                onSubmit={handleOnSubmit}
                onCancel={handleOnCancel}
                zone={getTimezoneById(range.timeZoneId).label}
                presetRanges={dateRangeWithDataFF ? uatPresets : presets}
                customPeriodId={customPeriod.id}
                cancelText="Cancel"
                saveText="Apply"
                dropdownLabelOverride={getDropdownLabel}
                {...extra}
            />
        );
    };

    return (
        <>
            {isMobile ? (
                <StyledSecondaryView
                    isOpen={active}
                    onClose={handleOnCancel}
                    secondaryViewElementId="portal"
                    toggleEl={buttonRef.current}
                    padding="none"
                    closeButtonLabel="Back">
                    {renderDateRangePicker(dateRangeWithDataFF)}
                </StyledSecondaryView>
            ) : (
                <Popover
                    position="overlay"
                    align="start"
                    offset={{ x: -5, y: -5 }}
                    isOpen={active}
                    anchorEl={buttonRef.current}
                    withBackdrop
                    onClose={handleOnCancel}
                    ref={popoverRef}
                    showFocus={showFocus}
                    attachCustomEvents={(element: any) => {
                        element.addEventListener('keydown', handleKeyDownPopover);
                    }}>
                    {renderDateRangePicker(dateRangeWithDataFF)}
                </Popover>
            )}

            <Button buttonRef={getButtonRef} onClick={handleToggle}>
                {getButtonText(selected)}
                &nbsp;&nbsp;
                <ButtonIcon type="calendar-alt" />
            </Button>

            <TimezomePicker timeZoneId={range.timeZoneId} onChange={handleChangeTimezone} />
        </>
    );
};
