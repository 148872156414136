import { createStore, combineReducers } from 'redux';
import { dateRangeReducer, placementTypesReducer } from './filters/reducers';
import { pageTitleReducer } from './app/reducers';
import { gridReducer } from './grid/reducers';

const reducers = combineReducers({
    app: pageTitleReducer,
    epochDateRange: dateRangeReducer,
    grid: gridReducer,
    placementTypes: placementTypesReducer,
});

export type AppStore = ReturnType<typeof reducers>;

export const store = createStore(reducers);
