import { PresetPeriod } from '../components/DateRange/presets';
import { DateRangeType, NaiveDateTime } from './types';

export function calculatePreviousDateRange(range: DateRangeType): DateRangeType {
    switch (range.period) {
        case PresetPeriod.lastMonth:
            const monthStart = range.from.toUTCDateTime().minus({ months: 1 }).startOf('month');
            return {
                from: NaiveDateTime.fromLocalTimestamp(monthStart.toSeconds()),
                to: NaiveDateTime.fromLocalTimestamp(monthStart.endOf('month').toSeconds()),
            };
        case PresetPeriod.today:
        case PresetPeriod.yesterday:
        case PresetPeriod.last7Days:
        case PresetPeriod.thisWeek:
        case PresetPeriod.lastWeek:
        case PresetPeriod.last30Days:
        case PresetPeriod.yearToDate:
        default: {
            const fromTs = range.from.toLocalTimestamp();
            const toTs = range.to.toLocalTimestamp();
            const duration = toTs - fromTs + 1;
            return {
                from: NaiveDateTime.fromLocalTimestamp(fromTs - duration),
                to: NaiveDateTime.fromLocalTimestamp(fromTs - 1),
            };
        }
    }
}
