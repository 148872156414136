import gql from "graphql-tag";

const query = gql`
query fetchCampaignById(
    $from: Long!
    $to: Long!
    $tzOffsetHours: Int
    $offset: Int!
    $limit: Int!
    $campaignId: Long!
    $filterByPlacementTypes: [Int!]
    $WITHOUTplacementType: Boolean = false
    $WITHOUTsiteName: Boolean = false
    $WITHOUTepochSec: Boolean = false
    $dimensions: [String!]!
    $sortProperty: String
    $sortDirection: Direction
    $search: String
) {
    rows: campaignById(
        from: $from
        to: $to
        tzOffsetHours: $tzOffsetHours
        offset: $offset
        limit: $limit
        campaignId: $campaignId
        filterByPlacementTypes: $filterByPlacementTypes
        sortProperty: $sortProperty
        sortDirection: $sortDirection
        search: $search
    ) {
        placementType @skip(if: $WITHOUTplacementType)
        siteId @skip(if: $WITHOUTsiteName)
        siteName @skip(if: $WITHOUTsiteName)
        epochSec @skip(if: $WITHOUTepochSec)
        clicks
        conversions
        ctr
        cvr
        dwellRate
        impressions
        impressionsWithDwell
        impressionsWithVideoStart
        interactionRate
        interactions
        recordableImpressions
        recordableImpressionsRate
        ssaiClicks
        ssaiImpressions
        videoFullyPlayed
        videoFullyPlayedRate
        videoStarted
        videoStartedRate
        videoPlayed25
        videoPlayed50
        videoPlayed75
        avgVideoPercentageComplete
        viewableImpressions
        viewableImpressionsRate
    }
    meta: campaignByIdTotalRows(
        from: $from
        to: $to
        campaignId: $campaignId
        filterByPlacementTypes: $filterByPlacementTypes
        dimensions: $dimensions
        search: $search
    ) {
        total
    }
}
`;
export default query;
