import {ColDef, ValueFormatterParams} from '@ag-grid-community/core';
import {DASH, formatEpochSec, formatNumber, formatPercent} from '../../../lib/formatters';

export const dateFormatter = (params: ValueFormatterParams) => {
    if (params.value) {
        return formatEpochSec(params.value);
    } else {
        return '';
    }
};

export const numberFormatter = (params: ValueFormatterParams) => {
    return formatNumber(params.value, DASH);
};

export enum columnTypes {
    isDefault = 'isDefault',
    isDimension = 'isDimension',
    isPinned = 'isPinned',
    isCPDOnly = 'isCPDOnly', // is column available on CPD grid only
    numeric = 'numeric',
    numericCounter = 'numericCounter',
    numericPercent = 'numericPercent',
    text = 'text',
    date = 'date',
}

export const COLUMN_TYPES_DEF: { [key in columnTypes]: ColDef } = {
    isDefault: {},
    isDimension: {
        pinned: 'left',
        initialPinned: 'left',
        lockPosition: true,
        suppressMovable: true,
        lockVisible: true,
        sortable: true,
    },
    isPinned: {
        pinned: 'left',
        initialPinned: 'left',
        lockPosition: true,
        suppressMovable: true,
        lockVisible: true,
    },
    isCPDOnly: {},
    numeric: {
        sortable: true,
        sortingOrder: ['desc', 'asc'],
        headerClass: 'ag-right-aligned-header',
        cellClass: 'ag-right-aligned-cell',
    },
    numericCounter: {
        valueFormatter: (params: ValueFormatterParams) => {
            return formatNumber(params.value, DASH);
        },
    },
    numericPercent: {
        valueFormatter: (params: ValueFormatterParams) => {
            return formatPercent(params.value, DASH, 4);
        },
    },
    text: {
        // flex: 2,
        // width: 250,
        minWidth: 100,
    },
    date: {
        width: 150,
        valueFormatter: dateFormatter,
        sortingOrder: ['desc', 'asc', null],
    },
};

export const columnTypesDef: { [key: string]: ColDef } = COLUMN_TYPES_DEF;
