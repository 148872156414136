import { EntityType, DateRangeType, PlacementTypes } from '../../lib/types';

export const SET_DATE_RANGE = 'SET_DATE_RANGE';
export const SET_PLACEMENT_TYPES = 'SET_PLACEMENT_TYPES';

export type DateRangeState = {
    epochDateRange: DateRangeType;
};

export type PlacementTypesState = {
    placementTypes: PlacementTypes[] | null;
};

export type EntityAction = {
    type: string;
    entity: EntityType;
};

export type DateRangeAction = {
    type: string;
    epochDateRange: DateRangeType;
};

export type PlacementTypesAction = {
    type: string;
} & PlacementTypesState;

export const setDateRange = (dateRange: DateRangeType): DateRangeAction => {
    return {
        type: SET_DATE_RANGE,
        epochDateRange: dateRange,
    };
};

export const setPlacementTypes = (placementTypes: PlacementTypes[] | null): PlacementTypesAction => {
    return {
        type: SET_PLACEMENT_TYPES,
        placementTypes,
    };
};
