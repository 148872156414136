import React from 'react';
import { EntityType } from '../../lib/types';
import { EntityLink } from './EntityLink';

export interface AccountLinkProps {
    readonly account: EntityType;
    readonly withType?: boolean;
    readonly withId?: boolean;
}

export const AccountLink: React.FC<AccountLinkProps> = props => (
    <>
        {props.withType ? <span>Account:&nbsp;</span> : null}
        <EntityLink to={`/accounts/${props.account.id}`} title={props.account.name}>
            {props.account.name}
            {props.withId ? <>&nbsp;({props.account.id})</> : null}
        </EntityLink>
    </>
);
