import { useLocalStorage, useLocalStorageJSON } from './useLocalStorage';

export const useAuth = (): { token: string; userId: number | undefined; accountId: number | undefined } => {
    const [accountLS] = useLocalStorageJSON('account');
    const [userLS] = useLocalStorageJSON('user');
    const [tokenLS] = useLocalStorage('Authorization');

    const token = (tokenLS || '').replace(/^"(.*)"$/, '$1');
    const userId = userLS?.id;
    const accountId = accountLS?.id;

    return { token, userId, accountId };
};
