import { DateTime } from 'luxon';
import { Ticker } from './tickers';
import { DateRangeType, allMetrics, MetricFormat } from './types';

export const DATE_FORMAT_ISO_8601 = 'yyyy-MM-dd';
export const DATE_FORMAT_FULL = 'MMM d, yyyy';
export const DATE_FORMAT_MEDIUM = 'MMM d';
export const DATE_FORMAT_SHORT = 'MMM d, HH:mm, z';
export const DATE_FORMAT_SHORTEST = 'HH:mm';
export const DATE_FORMAT_GRID = 'MMM d, yyyy';
export const DATE_FORMAT_GRID2 = 'HH:mm, z';

export const DASH = '\u2014'; //em dash
export const EN_DASH = '\u2013';

export const utcFromUnixTime = (seconds: number): DateTime => DateTime.fromSeconds(seconds, { zone: 'utc' });

export function formatEpochSec(epochSec: number): string {
    return utcFromUnixTime(epochSec).toFormat(DATE_FORMAT_MEDIUM);
}

export function formatEpochGrid(epochSec: number): [string, string] {
    if (epochSec) {
        const dt = utcFromUnixTime(epochSec);
        return [dt.toFormat(DATE_FORMAT_GRID), dt.toFormat(DATE_FORMAT_GRID2)];
    } else {
        return ['', ''];
    }
}

export function formatNumber(n: number | string, dash?: string): string {
    if (dash && (!n || n === '0')) {
        return dash;
    } else {
        return (+n).toLocaleString();
    }
}

function izNearZero(n: number, digits: number): boolean {
    for (let i = 0; i < digits; ++i) {
        n *= 10;
    }
    return (n | 0) === 0;
}

export function formatPercent(n: number, dash?: string, fractionDigits?: number): string {
    fractionDigits = fractionDigits ?? 1;
    if (dash && izNearZero(n, 2 + fractionDigits)) {
        return dash;
    } else {
        return `${(n * 100).toFixed(fractionDigits)}%`;
    }
}

export const formatXAxis = (s: number, groupBy: Ticker | undefined): string => {
    try {
        const dt = utcFromUnixTime(s);
        if (groupBy === Ticker.hours) {
            if (dt.toSeconds() === dt.startOf('day').toSeconds()) {
                return dt.toFormat(DATE_FORMAT_SHORT);
            } else {
                return dt.toFormat(DATE_FORMAT_SHORTEST);
            }
        } else {
            return dt.toFormat(DATE_FORMAT_MEDIUM);
        }
    } catch (e) {
        console.warn('cannot format XAxis, epochSec:', s);
        return '';
    }
};

export const formatNumberCompact = (n: number): string => {
    try {
        return Intl.NumberFormat('en-US', { notation: 'compact', compactDisplay: 'short' } as any).format(n);
    } catch (e) {
        console.warn('cannot format number', n);
        return '';
    }
};

export const formatYAxis = (y: number): string => {
    return formatNumberCompact(y);
};

export const formatTooltip = (label: string | number, groupBy: Ticker | undefined) => {
    try {
        return utcFromUnixTime(+label).toFormat(groupBy === Ticker.hours ? DATE_FORMAT_SHORT : DATE_FORMAT_MEDIUM);
    } catch (e) {
        console.warn('cannot format Tooltip, epochSec:', label);
        return '';
    }
};

export const dt = (curr: number, prev: number): number => {
    if (!prev && !curr) {
        return 0;
    } else if (!prev && curr) {
        return 1;
    } else {
        return (curr - prev) / prev;
    }
};

export const formatDateRange = (range: DateRangeType): string => {
    const df = range.from.toUTCDateTime();
    const dt = range.to.toUTCDateTime();
    if (df.day === dt.day && df.month === dt.month && df.year === dt.year) {
        return dt.toFormat('MMM d, yyyy');
    } else if (df.year === dt.year) {
        return df.toFormat('MMM d') + ' ' + EN_DASH + ' ' + dt.toFormat('MMM d, yyyy');
    } else {
        return df.toFormat('MMM d, yyyy') + ' ' + EN_DASH + ' ' + dt.toFormat('MMM d, yyyy');
    }
};

export const formatMetric = (value?: number, metric?: string): string => {
    const metricFormat = allMetrics.find(m => m.field === metric)?.format ?? MetricFormat.COUNT;
    return metricFormat === MetricFormat.COUNT ? formatNumber(value ?? 0, DASH) : formatPercent(value ?? 0, DASH, 4);
};

export const formatMetricCompact = (value?: number, metric?: string): string => {
    const metricFormat = allMetrics.find(m => m.field === metric)?.format ?? MetricFormat.COUNT;
    return metricFormat === MetricFormat.COUNT ? formatNumberCompact(value ?? 0) : formatPercent(value ?? 0, undefined, 2);
};
