import React from 'react';
import { Ticker } from '../../lib/tickers';
import styles from './ChartGroupBy.module.css';
import { ButtonGroup, ButtonGroupItem } from '@amzn/storm-ui';

interface ChartGroupByProps {
    groupBy?: Ticker;
    setGroupBy: (groupBy: Ticker) => void;
    datatestid: string;
}

export const ChartGroupBy: React.FC<ChartGroupByProps> = props => {

    const handleChangeGroupBy = (value: Ticker) => {
        props.setGroupBy(value);
    };

    return (
        <div className={styles.groupbySwitcher} datatestid={props.datatestid}>
            <ButtonGroup
                name="group-by-selctor"
                selectedValue={props.groupBy}
                onChange={handleChangeGroupBy}>
                <ButtonGroupItem value={Ticker.days}>Days</ButtonGroupItem>
                <ButtonGroupItem value={Ticker.hours}>Hours</ButtonGroupItem>
            </ButtonGroup>
        </div>
    );
};