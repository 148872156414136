import React from 'react';
import { Biaxial } from '../../components/Chart/Biaxial';
import { Ticker } from '../../lib/tickers';
import { DocumentNode, useQuery } from '@apollo/client';
import { AppError, AppWarning, Loading } from '../../components/Messages';
import { useDateRangeFilter, usePlacementTypesFilter } from '../../hooks/useFilters';
import { LocalTimestamp, Metric } from '../../lib/types';

export interface ChartProps {
    readonly query: DocumentNode;
    readonly queryVars: Record<string, unknown>;
    readonly groupBy: Ticker;
    readonly leftLine: Metric;
    readonly rightLine: Metric;
}

export const fillInGaps = (from: LocalTimestamp, to: LocalTimestamp, ticker: Ticker, data: any[]): any[] => {
    let duration;
    switch (ticker) {
        case Ticker.hours:
            duration = 60 * 60;
            break;
        case Ticker.days:
            duration = 24 * 60 * 60;
            break;
        default:
            return data;
    }

    const available = Object.fromEntries((data ?? []).map(pt => [pt.epochSec, pt]));
    const result = [];
    for (let epochSec = from; epochSec < to; epochSec += duration) {
        result.push(available[epochSec] ?? { epochSec });
    }
    return result;
};

export const Chart: React.FC<ChartProps> = props => {
    const { dateRange, timeZone } = useDateRangeFilter();
    const placementTypes = usePlacementTypesFilter();

    const localTsFrom = dateRange.from.toLocalTimestamp();
    const localTsTo = dateRange.to.toLocalTimestamp();

    const { loading, error, data } = useQuery(props.query, {
        variables: {
            ...props.queryVars,
            from: localTsFrom,
            to: localTsTo,
            tzOffsetHours: timeZone.tzOffsetHours,
            filterByPlacementTypes: placementTypes,
            groupBy: props.groupBy,
        },
    });

    if (loading) {
        return <Loading />;
    } else if (error) {
        return <AppError e={error} />;
    } else if (data) {
        const dataNoGaps = fillInGaps(localTsFrom, localTsTo, props.groupBy, data.chart);
        return (
            <Biaxial
                items={dataNoGaps}
                leftLine={props.leftLine}
                rightLine={props.rightLine}
                groupBy={props.groupBy}
                timeZone={timeZone}
            />
        );
    } else {
        return <AppWarning msg={'No chart data'} />;
    }
};
