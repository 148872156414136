import { useCallback } from 'react';
import { Dispatch } from 'redux';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as actions from '../store/app/actions';
import { AppStore } from '../store';
import { useLocalStorage } from './useLocalStorage';
import { useAuth } from './useAuth';

export const useSetPageTitle = (): ((title: string) => void) => {
    const dispatch: Dispatch<any> = useDispatch();
    return useCallback((title: string) => dispatch(actions.setPageTitle(title)), [dispatch]);
};

export const usePageTitle = (): string => {
    return useSelector((appStore: AppStore) => appStore.app.pageTitle, shallowEqual);
};

export const useLocalStorageHistoryURL = (): [string | null, (value: string) => void] => {
    const { userId, accountId } = useAuth();
    return useLocalStorage(`vaHistoryURL_${accountId}_${userId}`);
};
