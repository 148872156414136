import { InlineMessage } from '@amzn/storm-ui';
import React from 'react';
import styles from './Messages.module.css';

export interface MsgNoDataProps {
    readonly msg?: string;
}

export const MsgNoData: React.FC<MsgNoDataProps> = ({ msg }) => (
    <InlineMessage className={styles.msgCenter} default message={msg || 'No data available for selected date range.'} />
);
