import { useCallback } from 'react';
import { Dispatch } from 'redux';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { DateRangeType, getTimezoneById, PlacementTypes, TimeZoneDecriptor } from '../lib/types';
import * as actions from '../store/filters/actions';
import { AppStore } from '../store';

export const useDateRangeFilter = (): { dateRange: DateRangeType, timeZone: TimeZoneDecriptor } => {
    return useSelector((appStore: AppStore) => {
        const dateRange = appStore.epochDateRange;
        const timeZone = getTimezoneById(dateRange.timeZoneId);
        return { dateRange, timeZone };
    }, shallowEqual);
};

export const useSetDateRangeFilter = (): ((dateRange: DateRangeType) => void) => {
    const dispatch: Dispatch<any> = useDispatch();
    return useCallback((dateRange: DateRangeType) => dispatch(actions.setDateRange(dateRange)), [dispatch]);
};

export const usePlacementTypesFilter = (): PlacementTypes[] | null => {
    return useSelector((appStore: AppStore) => appStore.placementTypes, shallowEqual);
};

export const useSetPlacementTypesFilter = (): ((placementTypes: PlacementTypes[] | null) => void) => {
    const dispatch: Dispatch<any> = useDispatch();
    return useCallback((placementTypes: PlacementTypes[] | null) => dispatch(actions.setPlacementTypes(placementTypes)), [dispatch]);
};
