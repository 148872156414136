import React, { useState } from 'react';
import { Dropdown } from '../../components/Dropdown';
import { Metric, METRICS } from '../../lib/types';
import { HeatMapContainer } from '../../containers/HeatMap';
import { MiniChart } from '../../containers/MiniChart';

export interface CampaignHeatMapProps {
    readonly campaignId: number;
}

export const CampaignHeatMap: React.FC<CampaignHeatMapProps> = (props) => {
    const [metric, setMetric] = useState<Metric>('impressions');

    return (
        <MiniChart header={<>
            <Dropdown
                selected={metric}
                dictionary={METRICS}
                onChange={setMetric} />
            <span>by Day, Hour</span>
        </>} dataTestId="heatMap">
            <HeatMapContainer campaignId={props.campaignId} metric={metric} />
        </MiniChart >
    );
};
