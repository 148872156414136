import React from 'react';
import { EntityLink } from './EntityLink';

export interface AccountsLinkProps {
    readonly label: string;
}

export const AccountsLink: React.FC<AccountsLinkProps> = props => (
    <EntityLink to={'/accounts/'}>
        {props.label}
    </EntityLink>
);
