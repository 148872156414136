import React, { useState, useRef, ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import styles from './PopoverFilter.module.css';
import { Button, Modal, Checkbox, Icon, TextButton, CheckboxGroup } from '@amzn/storm-ui';
import classNames from 'classnames';

interface Item {
    key: string,
    value: string,
    group?: string,
}

interface ModalFilterProps {
    onSave: (items: string[]) => void;
    items: Item[];
    checkedItems: string[];
    defaultItems: string[];
    labelButton: string;
    labelPopover: string;
    showRemoveButton?: boolean;
    dataTestId: string;
}

export const ModalFilter: React.FC<ModalFilterProps> = props => {
    const { items, onSave } = props;

    const [isActive, setIsActive] = useState<boolean>(false);
    const [checkedItems, setCheckedItems] = useState<string[]>([]);

    useEffect(() => {
        setCheckedItems(items.filter(it => props.checkedItems.includes(it.key)).map(it => it.key));
    }, [props.checkedItems, items, isActive]);

    const toggleIsActive = useCallback(() => setIsActive(!isActive), [isActive, setIsActive]);

    const groups = useMemo(() => {
        return items.reduce((map, item) => {
            map.set(item.group ?? '', [...(map.get(item.group ?? '') ?? []), item]);
            return map;
        }, new Map<string, Item[]>());
    }, [items]);

    const stickTo = useRef(null);

    const handleOnChangeGroup = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setCheckedItems(
            [...items.filter(it => !!it.group && it.group !== event.target.id)
                .filter(it => checkedItems.includes(it.key)).map(it => it.key),
            ...items.filter(it => event.target.checked && (!it.group || it.group === event.target.id))
                .map(it => it.key)]
        );
    }, [items, checkedItems]);

    const handleOnSave = useCallback(() => {
        setIsActive(false);
        onSave(checkedItems);
    }, [checkedItems, onSave]);

    const handleOnCancel = useCallback(() => {
        setIsActive(false);
    }, []);

    const handleOnChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setCheckedItems(event.target.checked ? [...checkedItems, event.target.id] : checkedItems.filter(it => it !== event.target.id));
    }, [checkedItems]);

    const isCheckedItem = useCallback((key: string): boolean => checkedItems.includes(key), [checkedItems]);
    const isCheckedItems = (items: Item[]): boolean => items.every(item => isCheckedItem(item.key));

    const isDisabledSaveButton = useCallback((): boolean => {
        return Array.from(items.reduce((map, item) =>
            map.set(item.group, !!map.get(item.group) || checkedItems.includes(item.key)), new Map<string | undefined, boolean>())
            .values())
            .reduce((res, it) => res || !it, false)
    }, [checkedItems, items]);

    const Footer = useMemo(() => (
        <div className={styles.footer}>
            <TextButton onClick={handleOnCancel}>Cancel</TextButton>
            <Button primary onClick={handleOnSave} disabled={isDisabledSaveButton()}>Apply</Button>
        </div>
    ), [handleOnCancel, handleOnSave, isDisabledSaveButton]);

    return (
        <div className={styles.stateFilter} datatestid={`${props.dataTestId}-button`}>
            <div ref={stickTo}>
                <Button ref={stickTo} className={classNames(styles.filterButton, styles.noBorder)} small onClick={toggleIsActive}>
                    {props.labelButton} &nbsp;
                    <Icon type="angle-down" /></Button>
            </div>
            <Modal header={props.labelPopover}
                footer={Footer}
                isOpen={isActive}
                onClose={handleOnCancel}
                toggleEl={stickTo.current}>
                <div className={styles.content} datatestid={`${props.dataTestId}-modal`}>
                    {Array.from(groups.entries()).map(([group, items], groupIndex) =>
                        <CheckboxGroup key={groupIndex} label={group}>
                            <Checkbox
                                className={`${props.dataTestId}-${group}-select-deselect-all`}
                                key={group + groupIndex}
                                id={group || 'no-group'}
                                checked={isCheckedItems(items)}
                                onChange={handleOnChangeGroup}
                                label={"Select/Deselect all"} />
                            {items.sort((item, item1) => item.value > item1.value ? 1 : -1)
                                .map((item, index) =>
                                    <Checkbox
                                        className={`${props.dataTestId}-item-container`}
                                        key={item.key + index}
                                        id={item.key}
                                        checked={isCheckedItem(item.key)}
                                        onChange={handleOnChange}
                                        label={item.value} />
                                )}
                        </CheckboxGroup>
                    )}
                </div>
            </Modal>
        </div>
    );
};