import React, { ReactNode } from 'react';
import { formatEpochGrid } from '../../../lib/formatters';
import styles from './../Grid.module.css';

export interface TwoRowsCellProps {
    readonly desc?: string | ReactNode;
    readonly children?: React.ReactNode;
}

export const TwoRowsCellRenderer: React.FC<TwoRowsCellProps> = props => {
    return (
        <>
            <div className={styles.cellTitle}>{props.children}</div>
            <div className={styles.cellDesc}>{props.desc}</div>
        </>
    );
}

export interface DateCellRendererProps {
    value: number;
}

export const DateCellRenderer: React.FC<DateCellRendererProps> = props => {
    const [date, time] = formatEpochGrid(props.value);
    return (
        <TwoRowsCellRenderer desc={time}>
            {date}
        </TwoRowsCellRenderer>
    );
};

export const frameworkComponents = {
    twoRowsCellRenderer: TwoRowsCellRenderer,
    dateCellRenderer: DateCellRenderer,
};
