import { DateTime } from 'luxon';
import moment, { Moment } from 'moment';

export type LocalTimestamp = number;

export class NaiveDateTime {
    constructor(private readonly value: string) {}

    private static FORMAT = 'yyyy-MM-dd HH:mm:ss';
    private static MOMENT_FORMAT = 'YYYY-MM-DD HH:mm:ss';

    static fromUTCDateTime(dt: DateTime): NaiveDateTime {
        return new NaiveDateTime(dt.toFormat(NaiveDateTime.FORMAT));
    }

    static fromString(s: string): NaiveDateTime {
        return NaiveDateTime.fromUTCDateTime(DateTime.fromFormat(s, NaiveDateTime.FORMAT, { zone: 'utc' }));
    }

    static fromLocalTimestamp(ts: LocalTimestamp): NaiveDateTime {
        return NaiveDateTime.fromUTCDateTime(DateTime.fromSeconds(ts | 0, { zone: 'utc' }));
    }

    static fromMoment(m: Moment): NaiveDateTime {
        return new NaiveDateTime(moment(m).format(NaiveDateTime.MOMENT_FORMAT));
    }

    startOfDay(): NaiveDateTime {
        return new NaiveDateTime(this.value.substring(0, 10) + ' 00:00:00');
    }

    endOfDay(): NaiveDateTime {
        return new NaiveDateTime(this.value.substring(0, 10) + ' 23:59:59');
    }

    toUTCDateTime(): DateTime {
        return DateTime.fromFormat(this.value, NaiveDateTime.FORMAT, { zone: 'utc' });
    }

    toLocalTimestamp(): LocalTimestamp {
        return this.toUTCDateTime().toSeconds() | 0;
    }

    toMoment(): Moment {
        return moment(this.value, NaiveDateTime.MOMENT_FORMAT);
    }
}

export type TimeZoneId = string;

export interface TimeZoneDecriptor {
    readonly id: TimeZoneId;
    readonly tzOffsetHours: number;
    readonly shortLabel: string;
    readonly label: string;
}

export const TIMEZONES: TimeZoneDecriptor[] = [
    // { id: 'AoE',     tzOffsetHours: -12, shortLabel: 'UTC -12',  label: 'UTC -12, Anywhere on Earth (AoE)' },
    { id: 'SST',          tzOffsetHours: -11, shortLabel: 'UTC -11',  label: 'UTC -11, Samoa Standard Time (SST)' },
    { id: 'CKT',          tzOffsetHours: -10, shortLabel: 'UTC -10',  label: 'UTC -10, Cook Island Time (CKT)' },
    { id: 'HST',          tzOffsetHours: -10, shortLabel: 'UTC -10',  label: 'UTC -10, Hawaii Standard Time (HST)' },
    { id: 'HDT',          tzOffsetHours: -9,  shortLabel: 'UTC -9',   label: 'UTC -9, Hawaii-Aleutian Daylight Time (HDT)' },
    { id: 'HADT',         tzOffsetHours: -9,  shortLabel: 'UTC -9',   label: 'UTC -9, Hawaii Daylight Time (HADT)' },
    { id: 'PST',          tzOffsetHours: -8,  shortLabel: 'UTC -8',   label: 'UTC -8, Pacific Standard Time (PST)' },
    { id: 'AKDT',         tzOffsetHours: -8,  shortLabel: 'UTC -8',   label: 'UTC -8, Alaska Dailight Time (AKDT)' },
    { id: 'PDT',          tzOffsetHours: -7,  shortLabel: 'UTC -7',   label: 'UTC -7, Pacific Dailight Time (PDT)' },
    { id: 'MST',          tzOffsetHours: -7,  shortLabel: 'UTC -7',   label: 'UTC -7, Mountain Standard Time (MST)' },
    { id: 'CST',          tzOffsetHours: -6,  shortLabel: 'UTC -6',   label: 'UTC -6, Central Standard Time (CST)' },
    { id: 'MDT',          tzOffsetHours: -6,  shortLabel: 'UTC -6',   label: 'UTC -6, Mountain Dailight Time (MDT)' },
    { id: 'CDT',          tzOffsetHours: -5,  shortLabel: 'UTC -5',   label: 'UTC -5, Central Daylight Time (CDT)' },
    { id: 'EST',          tzOffsetHours: -5,  shortLabel: 'UTC -5',   label: 'UTC -5, Eastern Standard Time (EST)' },
    { id: 'AST',          tzOffsetHours: -4,  shortLabel: 'UTC -4',   label: 'UTC -4, Atlantic Standard Time (AST)' },
    { id: 'EDT',          tzOffsetHours: -4,  shortLabel: 'UTC -4',   label: 'UTC -4, Eastern Daylight Time (EDT)' },
    { id: 'ADT',          tzOffsetHours: -3,  shortLabel: 'UTC -3',   label: 'UTC -3, Atlantic Daylight Time (ADT)' },
    { id: 'ART',          tzOffsetHours: -3,  shortLabel: 'UTC -3',   label: 'UTC -3, Argentina Time (ART)' },
    { id: 'BRT',          tzOffsetHours: -3,  shortLabel: 'UTC -3',   label: 'UTC -3, Brazilian Time (BRT)' },
    { id: 'WGT',          tzOffsetHours: -3,  shortLabel: 'UTC -3',   label: 'UTC -3, Western Greenland Time (WGT)' },
    { id: 'BRST',         tzOffsetHours: -2,  shortLabel: 'UTC -2',   label: 'UTC -2, Brazilian Summer Time (BRST)' },
    { id: 'WGST',         tzOffsetHours: -2,  shortLabel: 'UTC -2',   label: 'UTC -2, Western Greenland Summer Time (WGST)' },
    { id: 'AZOT',         tzOffsetHours: -1,  shortLabel: 'UTC -1',   label: 'UTC -1, Azores Time (AZOT)' },
    { id: 'CVT',          tzOffsetHours: -1,  shortLabel: 'UTC -1',   label: 'UTC -1, Cape Verde Time (CVT)' },
    { id: 'EGT',          tzOffsetHours: -1,  shortLabel: 'UTC -1',   label: 'UTC -1, Eastern Greenland Time (EGT)' },
    { id: 'UTC',          tzOffsetHours: 0,   shortLabel: 'UTC',      label: 'UTC, Coordinated Universal Time (UTC)' },
    { id: 'GMT',          tzOffsetHours: 0,   shortLabel: 'UTC +0',   label: 'UTC +0, Greenwich Mean Time (GMT)' },
    { id: 'AZOST',        tzOffsetHours: 0,   shortLabel: 'UTC +0',   label: 'UTC +0, Azores Summer Time (AZOST)' },
    { id: 'EGST',         tzOffsetHours: 0,   shortLabel: 'UTC +0',   label: 'UTC +0, Eastern Greenland Summer Time (EGST)' },
    { id: 'WET',          tzOffsetHours: 0,   shortLabel: 'UTC +0',   label: 'UTC +0, Western European Time (WET)' },
    { id: 'BST',          tzOffsetHours: 1,   shortLabel: 'UTC +1',   label: 'UTC +1, British Summer Time (BST)' },
    { id: 'CET',          tzOffsetHours: 1,   shortLabel: 'UTC +1',   label: 'UTC +1, Central European Time (CET)' },
    { id: 'IST',          tzOffsetHours: 1,   shortLabel: 'UTC +1',   label: 'UTC +1, Irish Summer Time (IST)' },
    { id: 'WEST',         tzOffsetHours: 1,   shortLabel: 'UTC +1',   label: 'UTC +1, Western European Summer Time (WEST)' },
    { id: 'CAT',          tzOffsetHours: 2,   shortLabel: 'UTC +2',   label: 'UTC +2, Central Africa Time (CAT)' },
    { id: 'CEST',         tzOffsetHours: 2,   shortLabel: 'UTC +2',   label: 'UTC +2, Central European Summer Time (CEST)' },
    { id: 'EET',          tzOffsetHours: 2,   shortLabel: 'UTC +2',   label: 'UTC +2, Eastern European Time (EET)' },
    { id: 'IsraelST',     tzOffsetHours: 2,   shortLabel: 'UTC +2',   label: 'UTC +2, Israel Standard Time (IST)' },
    { id: 'SAST',         tzOffsetHours: 2,   shortLabel: 'UTC +2',   label: 'UTC +2, South Africa Standard Time (SAST)' },
    { id: 'WAST',         tzOffsetHours: 2,   shortLabel: 'UTC +2',   label: 'UTC +2, West Africa Summer Time (WAST)' },
    { id: 'ArabiaST',     tzOffsetHours: 3,   shortLabel: 'UTC +3',   label: 'UTC +3, Arabia Standard Time (AST)' },
    { id: 'EAT',          tzOffsetHours: 3,   shortLabel: 'UTC +3',   label: 'UTC +3, Eastern Africa Time (EAT)' },
    { id: 'EEST',         tzOffsetHours: 3,   shortLabel: 'UTC +3',   label: 'UTC +3, Eastern European Summer Time (EEST)' },
    { id: 'IDT',          tzOffsetHours: 3,   shortLabel: 'UTC +3',   label: 'UTC +3, Israel Dailight Time (IDT)' },
    { id: 'MSK',          tzOffsetHours: 3,   shortLabel: 'UTC +3',   label: 'UTC +3, Moscow Standard Time (MSK)' },
    { id: 'TRT',          tzOffsetHours: 3,   shortLabel: 'UTC +3',   label: 'UTC +3, Turkey Time (TRT)' },
    { id: 'ArabiaDT',     tzOffsetHours: 4,   shortLabel: 'UTC +4',   label: 'UTC +4, Arabia Dailight Time (ADT)' },
    { id: 'MSD',          tzOffsetHours: 4,   shortLabel: 'UTC +4',   label: 'UTC +4, Moscow Dailight Time (MSD)' },
    { id: 'PKT',          tzOffsetHours: 5,   shortLabel: 'UTC +5',   label: 'UTC +5, Pakistan Standard Time (PKT)' },
    { id: 'YEKT',         tzOffsetHours: 5,   shortLabel: 'UTC +5',   label: 'UTC +5, Yekaterinburg Time (YEKT)' },
    { id: 'ALMT',         tzOffsetHours: 6,   shortLabel: 'UTC +6',   label: 'UTC +6, Alma-Ata Time (ALMT)' },
    { id: 'BangladeshST', tzOffsetHours: 6,   shortLabel: 'UTC +6',   label: 'UTC +6, Bangladesh Standard Time (BST)' },
    { id: 'ICT',          tzOffsetHours: 7,   shortLabel: 'UTC +7',   label: 'UTC +7, Indochina Time (ICT)' },
    { id: 'WIB',          tzOffsetHours: 7,   shortLabel: 'UTC +7',   label: 'UTC +7, Wetern Indonesian Time (WIB)' },
    { id: 'AWST',         tzOffsetHours: 8,   shortLabel: 'UTC +8',   label: 'UTC +8, Australian Western Standard Time (AWST)' },
    { id: 'ChinaST',      tzOffsetHours: 8,   shortLabel: 'UTC +8',   label: 'UTC +8, China Standard Time (CST)' },
    { id: 'HKT',          tzOffsetHours: 8,   shortLabel: 'UTC +8',   label: 'UTC +8, Hong Kong Time (HKT)' },
    { id: 'MYT',          tzOffsetHours: 8,   shortLabel: 'UTC +8',   label: 'UTC +8, Malaysia Time (MYT)' },
    { id: 'PHT',          tzOffsetHours: 8,   shortLabel: 'UTC +8',   label: 'UTC +8, Philippine Time (PHT)' },
    { id: 'SGT',          tzOffsetHours: 8,   shortLabel: 'UTC +8',   label: 'UTC +8, Singapore Time (SGT)' },
    { id: 'WITA',         tzOffsetHours: 8,   shortLabel: 'UTC +8',   label: 'UTC +8, Central Indonesian Time (WITA)' },
    { id: 'AWDT',         tzOffsetHours: 9,   shortLabel: 'UTC +9',   label: 'UTC +9, Australian Western Daylight Time (AWDT)' },
    { id: 'JST',          tzOffsetHours: 9,   shortLabel: 'UTC +9',   label: 'UTC +9, Japan Standard Time (JST)' },
    { id: 'KST',          tzOffsetHours: 9,   shortLabel: 'UTC +9',   label: 'UTC +9, Korea Standard Time (KST)' },
    { id: 'WIT',          tzOffsetHours: 9,   shortLabel: 'UTC +9',   label: 'UTC +9, Eastern Indonesian Time (WIT)' },
    { id: 'AEST',         tzOffsetHours: 10,  shortLabel: 'UTC +10',  label: 'UTC +10, Australian Eastern Standard Time (AEST)' },
    { id: 'PGT',          tzOffsetHours: 10,  shortLabel: 'UTC +10',  label: 'UTC +10, Papua New Guinea Time (PGT)' },
    { id: 'AEDT',         tzOffsetHours: 11,  shortLabel: 'UTC +11',  label: 'UTC +11, Australian Eastern Daylight Time (AEDT)' },
    { id: 'MAGT',         tzOffsetHours: 11,  shortLabel: 'UTC +11',  label: 'UTC +11, Magadan Time (MAGT)' },
    { id: 'SBT',          tzOffsetHours: 11,  shortLabel: 'UTC +11',  label: 'UTC +11, Solomon Islands Time (SBT)' },
    { id: 'FJT',          tzOffsetHours: 12,  shortLabel: 'UTC +12',  label: 'UTC +12, Fiji Time (FJT)' },
    { id: 'MAGST',        tzOffsetHours: 12,  shortLabel: 'UTC +12',  label: 'UTC +12, Magadan Summer Time (MAGST)' },
    { id: 'NZST',         tzOffsetHours: 12,  shortLabel: 'UTC +12',  label: 'UTC +12, New Zealand Standard Time (NZST)' },
];

export function findTimezoneById(timeZoneId?: TimeZoneId): TimeZoneDecriptor | undefined {
    return TIMEZONES.find(tz => tz.id === timeZoneId);
}

export function getTimezoneById(timeZoneId?: TimeZoneId): TimeZoneDecriptor {
    return findTimezoneById(timeZoneId) ?? findTimezoneById('UTC')!!;
}

export interface DateRangeType {
    readonly period?: string;
    readonly from: NaiveDateTime;
    readonly to: NaiveDateTime;
    readonly timeZoneId?: TimeZoneId;
}

export interface EntityType {
    readonly id: number;
    readonly name: string | undefined;
    readonly parentId?: number;
}

export const METRICS = {
    clicks: 'Clicks (net)',
    conversions: 'Total conversions',
    impressions: 'Impressions (net)',
    impressionsWithDwell: 'Impressions with dwell',
    impressionsWithVideoStart: 'Impressions with video start',
    interactions: 'Total interactions',
    recordableImpressions: 'Recordable impressions (IAB)',
    ssaiClicks: 'SSAI clicks',
    ssaiImpressions: 'SSAI impressions',
    videoFullyPlayed: 'Video fully played',
    videoStarted: 'Video started',
    viewableImpressions: 'Total viewable impressions (IAB)',
    videoPlayed25: 'Video 25% played',
    videoPlayed50: 'Video 50% played',
    videoPlayed75: 'Video 75% played',
};

export const CPD_METRICS = {
    ...METRICS,
    clicks: 'Clicks (net)',
    conversions: 'Total conversions',
    ctr: 'CTR',
    cvr: 'Conversion rate',
    dwellRate: 'Dwell rate',
    impressions: 'Impressions (net)',
    impressionsWithDwell: 'Impressions with dwell',
    impressionsWithVideoStart: 'Impressions with video start',
    interactionRate: 'Interaction rate',
    interactions: 'Total interactions',
    recordableImpressions: 'Recordable impressions (IAB)',
    recordableImpressionsRate: 'Recordable impressions rate (IAB)',
    ssaiClicks: 'SSAI clicks',
    ssaiImpressions: 'SSAI impressions',
    videoFullyPlayed: 'Video fully played',
    videoFullyPlayedRate: 'Video fully played rate',
    videoStarted: 'Video started',
    videoPlayed25: 'Video 25% played',
    videoPlayed50: 'Video 50% played',
    videoPlayed75: 'Video 75% played',
    videoStartedRate: 'Video started rate',
    avgVideoPercentageComplete: 'Avg. video percentage complete',
    viewableImpressions: 'Total viewable impressions (IAB)',
    viewableImpressionsRate: 'Viewable impressions rate (IAB)',
};

export type Metric = keyof typeof CPD_METRICS;

export interface MetricValues {
    readonly curr: number | undefined;
    readonly prev: number | undefined;
}

export enum PlacementTypes {
    inBanner = 12,
    trackingOnly = 6,
    inStreamVideo = 9,
    inStreamVideoTracking = 10,
}

export const PLACEMENT_TYPES = {
    [PlacementTypes.inBanner]: 'In banner',
    [PlacementTypes.trackingOnly]: 'Tracking only',
    [PlacementTypes.inStreamVideo]: 'In-stream video',
    [PlacementTypes.inStreamVideoTracking]: 'In-stream video tracking',
};

export enum Pages {
    ACCOUNTS = 'All accounts',
    ACCOUNT = 'Account',
    ADVERTISER = 'Advertiser',
    CAMPAIGN = 'Campaign',
}

export const ALL_PAGES: Pages[] = [Pages.ACCOUNTS, Pages.ACCOUNT, Pages.ADVERTISER, Pages.CAMPAIGN];

export const CPD_DIMENSIONS = {
    siteName: 'Site',
    placementType: 'Placement type',
    placementSize: 'Placement size',
    environment: 'Environment',
    platform: 'Device OS',
    deviceType: 'Device type',
};

export type Dimension = keyof typeof CPD_DIMENSIONS;

export type Dictionary = typeof METRICS | typeof CPD_DIMENSIONS | typeof CPD_METRICS;

export const getKeyValue = <U extends keyof T, T extends object>(key: U) => (obj: T) => obj[key];

export enum MetricFormat {
    COUNT = 'count',
    RATE = 'rate',
}

export interface MetricInfo {
    readonly field: Metric;
    readonly title: string;
    readonly availableOnPages: Pages[];
    readonly defaultOnPages: Pages[];
    readonly tag?: string;
    readonly description: string;
    readonly format: MetricFormat;
}

export const allMetrics: MetricInfo[] = [
    {
        field: 'impressions',
        title: 'Impressions (net)',
        availableOnPages: ALL_PAGES,
        defaultOnPages: ALL_PAGES,
        description: '(Display and Video) Total number of impressions that were not identified as GIVT.',
        // tag: 'MRC',
        format: MetricFormat.COUNT,
    },
    {
        field: 'clicks',
        title: 'Clicks (net)',
        availableOnPages: ALL_PAGES,
        defaultOnPages: ALL_PAGES,
        description:
            'The total number of clicks tracked from all ads, excluding Default Interactions from Tracked Ads (clicks on the ads served from <noscript> of a Rich Media creative).',
        // tag: 'MRC',
        format: MetricFormat.COUNT,
    },
    {
        field: 'interactions',
        title: 'Total interactions',
        availableOnPages: ALL_PAGES,
        defaultOnPages: ALL_PAGES,
        description:
            'Total number of user-initiated interactions. The number can include clicks, opening (expanding) a panel, video mute, video sound on, video pause, full screen video mode start/pause/end, and any SAS custom interactions defined in an ad.',
        format: MetricFormat.COUNT,
    },
    {
        field: 'conversions',
        title: 'Total conversions',
        availableOnPages: ALL_PAGES,
        defaultOnPages: ALL_PAGES,
        description: 'All conversions that occurred as a result of an ad impression or an ad click.',
        format: MetricFormat.COUNT,
    },
    {
        field: 'ssaiImpressions',
        title: 'SSAI impressions',
        availableOnPages: ALL_PAGES,
        defaultOnPages: [],
        description: 'Server-initiated impressions were received from the publisher using the Server Side Ad Insertion (SSAI) ad delivery method.',
        format: MetricFormat.COUNT,
    },
    {
        field: 'ssaiClicks',
        title: 'SSAI clicks',
        availableOnPages: ALL_PAGES,
        defaultOnPages: [],
        description: 'Server-initiated clicks were received from the publisher using the Server Side Ad Insertion (SSAI) ad delivery method.',
        format: MetricFormat.COUNT,
    },
    {
        field: 'impressionsWithDwell',
        title: 'Impressions with dwell',
        availableOnPages: ALL_PAGES,
        defaultOnPages: ALL_PAGES,
        description: 'The total number of impressions for which users engaged with the ad for at least one second.',
        format: MetricFormat.COUNT,
    },
    {
        field: 'impressionsWithVideoStart',
        title: 'Impressions with video start',
        availableOnPages: ALL_PAGES,
        defaultOnPages: [],
        description:
            'The number of impressions with at least one instance of a user starting to play video. This Unique metric can only be fired once per user, per session.',
        format: MetricFormat.COUNT,
    },
    {
        field: 'videoStarted',
        title: 'Video started',
        availableOnPages: ALL_PAGES,
        defaultOnPages: [],
        description: 'Identifies when the player renders the first video frame.',
        format: MetricFormat.COUNT,
    },
    {
        field: 'videoPlayed25',
        title: 'Video 25% played',
        availableOnPages: ALL_PAGES,
        defaultOnPages: [],
        description: "The number of times the video played at least 25% of the video's duration.",
        format: MetricFormat.COUNT,
    },
    {
        field: 'videoPlayed50',
        title: 'Video 50% played',
        availableOnPages: ALL_PAGES,
        defaultOnPages: [],
        description: "The number of times the video played at least 50% of the video's duration.",
        format: MetricFormat.COUNT,
    },
    {
        field: 'videoPlayed75',
        title: 'Video 75% played',
        availableOnPages: ALL_PAGES,
        defaultOnPages: [],
        description: "The number of times the video played at least 75% of the video's duration.",
        format: MetricFormat.COUNT,
    },
    {
        field: 'videoFullyPlayed',
        title: 'Video fully played',
        availableOnPages: ALL_PAGES,
        defaultOnPages: [],
        description: 'The number of times the full duration of the video was played.',
        format: MetricFormat.COUNT,
    },
    {
        field: 'recordableImpressions',
        title: 'Recordable impressions (IAB)',
        availableOnPages: ALL_PAGES,
        defaultOnPages: [],
        description: 'The number of impressions successfully measured for viewability evaluation purposes according to the IAB.',
        // tag: 'MRC',
        format: MetricFormat.COUNT,
    },
    {
        field: 'viewableImpressions',
        title: 'Total viewable impressions (IAB)',
        availableOnPages: ALL_PAGES,
        defaultOnPages: [],
        description: 'The number of viewable impressions measured based on IAB viewability thresholds.',
        format: MetricFormat.COUNT,
    },
    {
        field: 'ctr',
        title: 'CTR',
        availableOnPages: [Pages.CAMPAIGN],
        defaultOnPages: [Pages.CAMPAIGN],
        description: 'Click-through rate. The percentage of clicks out of the number of Impressions (net).',
        format: MetricFormat.RATE,
    },
    {
        field: 'interactionRate',
        title: 'Interaction rate',
        availableOnPages: [Pages.CAMPAIGN],
        defaultOnPages: [Pages.CAMPAIGN],
        description: 'The ratio of total interactions to Impressions (net), expressed as a percentage.',
        format: MetricFormat.RATE,
    },
    {
        field: 'cvr',
        title: 'Conversion rate',
        availableOnPages: [Pages.CAMPAIGN],
        defaultOnPages: [Pages.CAMPAIGN],
        description: 'The ratio between the number of Total сonversions and the number of Impressions (net).',
        format: MetricFormat.RATE,
    },
    {
        field: 'dwellRate',
        title: 'Dwell rate',
        availableOnPages: [Pages.CAMPAIGN],
        defaultOnPages: [],
        description:
            'The ratio between the number of impressions with dwell (one instance of dwell is counted for each impression), and the number of Impressions (net).',
        format: MetricFormat.RATE,
    },
    {
        field: 'videoStartedRate',
        title: 'Video started rate',
        availableOnPages: [Pages.CAMPAIGN],
        defaultOnPages: [],
        description: 'The percentage of times the video started out of the number of Impressions (net)with potential for video starting.',
        format: MetricFormat.RATE,
    },
    {
        field: 'videoFullyPlayedRate',
        title: 'Video fully played rate',
        availableOnPages: [Pages.CAMPAIGN],
        defaultOnPages: [],
        description: 'The percentage of times the video was fully played out of the number of times video started.',
        format: MetricFormat.RATE,
    },
    {
        field: 'avgVideoPercentageComplete',
        title: 'Avg. video percentage complete',
        availableOnPages: [Pages.CAMPAIGN],
        defaultOnPages: [],
        description: `The average percentage of video viewed continuously at normal speed. Each section of video can only be considered once in the calculation. If a rewind event occurs while a video is playing, this metric represents the total amount of unduplicated video viewed at normal speed.`,
        format: MetricFormat.RATE,
    },
    {
        field: 'recordableImpressionsRate',
        title: 'Recordable impressions rate (IAB)',
        availableOnPages: [Pages.CAMPAIGN],
        defaultOnPages: [],
        description:
            'The percentage of impressions, out of the total number of Impressions (net), which were successfully recorded for viewability evaluation purposes.',
        // tag: 'MRC',
        format: MetricFormat.RATE,
    },
    {
        field: 'viewableImpressionsRate',
        title: 'Viewable impressions rate (IAB)',
        availableOnPages: [Pages.CAMPAIGN],
        defaultOnPages: [],
        description: 'The percentage of viewable impressions based on the IAB viewability thresholds, out of recordable impressions.',
        // tag: 'MRC',
        format: MetricFormat.RATE,
    },
];
