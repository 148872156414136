import React from 'react';
import { Dashboard } from '../../layouts';
import { CampaignsGrid } from './CampaignsGrid';
import { CampaignsJumbo } from './CampaignsJumbo';
import { Loading, AppError } from '../../components/Messages';
import { useAdvertiserParam } from '../../hooks/useParams';
import { useHistory } from 'react-router-dom';
import { useDefaultAdvertiser } from '../../hooks/useDefaults';
import { AccountLink, AccountsLink } from '../../components/PageLink';
import { AdvertiserMeta } from '../../layouts/Header';
import { useLocalStorageHistoryURL } from '../../hooks/useApp';

export const AdvertiserPage: React.FC = () => {
    const history = useHistory();
    const [, setLsHistory] = useLocalStorageHistoryURL();

    const { advertiser, account, error, loading } = useAdvertiserParam();

    const {
        advertiser: defaultAdvertiser,
        account: defaultAccount,
        error: defaultAdvertiserError,
        loading: defaultAdvertiserLoading,
    } = useDefaultAdvertiser(!!advertiser || loading);

    if (loading || defaultAdvertiserLoading) return <Loading />;
    if (error || defaultAdvertiserError) return <AppError e={error || defaultAdvertiserError} />;

    if (advertiser && account) {
        setLsHistory(history.location.pathname); // track last visited advertiser

        return (
            <Dashboard
                breadcrumbs={[
                    <AccountsLink label="All accounts" />,
                    <AccountLink withId withType account={account} />,
                ]}
                header={<AdvertiserMeta entity={advertiser} />}
                jumbo={<CampaignsJumbo advertiserId={advertiser.id} />}
                grid={<CampaignsGrid advertiserId={advertiser.id} />} />
        );
    }

    if (defaultAdvertiser && defaultAccount) {
        history.replace(`/advertisers/${defaultAdvertiser.id}`, { advertiser: defaultAdvertiser, account: defaultAccount });
    } else if (!defaultAdvertiserLoading) {
        history.replace('/accounts');
    }

    return <Loading />;
};
