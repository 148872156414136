import React, { useCallback, useMemo } from 'react';
import { PopoverFilter } from '../../components/PopoverFilter';
import { PlacementTypes, PLACEMENT_TYPES } from '../../lib/types';
import { usePlacementTypesFilter, useSetPlacementTypesFilter } from '../../hooks/useFilters';

const items: { key: PlacementTypes, value: string }[] = [
    { key: PlacementTypes.inBanner, value: PLACEMENT_TYPES[PlacementTypes.inBanner] },
    { key: PlacementTypes.trackingOnly, value: PLACEMENT_TYPES[PlacementTypes.trackingOnly] },
    { key: PlacementTypes.inStreamVideo, value: PLACEMENT_TYPES[PlacementTypes.inStreamVideo] },
    { key: PlacementTypes.inStreamVideoTracking, value: PLACEMENT_TYPES[PlacementTypes.inStreamVideoTracking] }
];
export const PlacementFilter: React.FC = props => {

    const placementTypes = usePlacementTypesFilter();
    const setPlacementTypesFilter = useSetPlacementTypesFilter();

    const handleOnSave = useCallback((checkedItems: string[] | null) => {
        setPlacementTypesFilter(checkedItems ? items.filter(it => checkedItems.includes(it.key.toString())).map(it => it.key) : null);
    }, [setPlacementTypesFilter]);

    const checkedItems = useMemo(() => items.filter(it => placementTypes?.includes(it.key)).map(it => it.key), [placementTypes]);

    return (
        <PopoverFilter
            onSave={handleOnSave}
            items={items.map(item => { return { key: item.key.toString(), value: item.value } })}
            checkedItems={checkedItems.map(placementType => placementType.toString())}
            labelPopover="Placement type"
            dataTestId="filter-by"
            labelButton={checkedItems.length === 0 || checkedItems.length === items.length ? 'All placement types' :
                checkedItems.map(it => PLACEMENT_TYPES[it]).join(', ')} />
    );
}