import React from 'react';
import styles from './PageTitle.module.css';

export const PageTitle: React.FC<{ name?: string; type?: string }> = ({ name, type }) =>
    name ? (
        <h3 className={styles.pageTitle} datatestid="page-title">
            {type ? <span className={styles.type}>{type}: </span> : null}
            {name}
        </h3>
    ) : null;
