import React from 'react';
import { Dashboard } from '../../layouts';
import { AdvertisersGrid } from './AdvertisersGrid';
import { AdvertisersJumbo } from './AdvertisersJumbo';
import { useAccountParam } from '../../hooks/useParams';
import { Loading, AppError } from '../../components/Messages';
import { AccountsLink } from '../../components/PageLink';
import { AccountMeta } from '../../layouts/Header';

export const AccountPage: React.FC = () => {
    const { account, error, loading } = useAccountParam();

    if (loading) return <Loading />;
    if (error) return <AppError e={error} />

    if (account) {
        return (
            <Dashboard
                breadcrumbs={[
                    <AccountsLink label="All accounts" />,
                ]}
                header={<AccountMeta entity={account} />}
                jumbo={<AdvertisersJumbo accountId={account.id} />}
                grid={<AdvertisersGrid accountId={account.id} />}
            />
        );
    }

    return <AppError />;
};
