import React from 'react';
import { Modal } from '@amzn/storm-ui';
import styles from './FeedbackContent.module.css';

interface GenericFeedbackModalProps {
    readonly isModalOpen: boolean;
    readonly onClose: () => void;
    readonly footer: any;
    readonly children?: React.ReactNode;
}

export const GenericFeedbackModal: React.FC<GenericFeedbackModalProps> = props => {
    return (
        <Modal header="Feedback for Visual Analytics" isOpen={props.isModalOpen} onClose={props.onClose} footer={props.footer}>
            <div className={styles.contentWrapper}>{props.children}</div>
        </Modal>
    );
};
