import React from 'react';
import { Alert, TextButton } from '@amzn/storm-ui';
import styles from './FeedbackContent.module.css';

interface FeedbackAlertProps {
    readonly alertType: string;
    readonly text: string;
    readonly onClose: () => void;
}

export const FeedbackAlert: React.FC<FeedbackAlertProps> = ({ alertType, text, onClose }) => {
    return (
        <div className={styles.alertWrapper}>
            <Alert type={alertType} header="">
                {text}
                <br />
                <TextButton onClick={onClose}>Close window</TextButton>
            </Alert>
        </div>
    );
};
