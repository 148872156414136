import gql from "graphql-tag";

const query = gql`
query campaignByIdHeatmap($metric: String!, $from: Long!, $to: Long!, $tzOffsetHours: Int, $campaignId: Long!, $filterByPlacementTypes: [Int!]) {
    heatmap: campaignByIdHeatmap(
        metric: $metric
        from: $from
        to: $to
        tzOffsetHours: $tzOffsetHours
        campaignId: $campaignId
        filterByPlacementTypes: $filterByPlacementTypes
    ) {
        dayOfWeek
        hourOfDay
        metric
    }
}
`;
export default query;
