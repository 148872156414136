import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { AccountsPage } from './pages/AccountsPage';
import { AccountPage } from './pages/AccountPage';
import { AdvertiserPage } from './pages/AdvertiserPage';
import { CampaignPage } from './pages/CampaignPage';
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import 'react-dates/initialize';
import { API_GRAPHQL_URL } from './lib/url-helpers';
import { setContext } from '@apollo/client/link/context';
import { Provider } from 'react-redux';
import { store } from './store';
import { useAuth } from './hooks/useAuth';
import { useLocalStorageHistoryURL } from './hooks/useApp';

function App() {
    //msg based auth for dev
    useEffect(() => {
        window.onmessage = (event: MessageEvent) => {
            if (event && event.data && event.origin === 'https://platform.sizmek.com') {
                try {
                    console.log('on message event:', JSON.parse(event.data).key);
                    window.localStorage.setItem('Authorization', JSON.parse(event.data).key);
                } catch {
                    console.log('cannot parse json Authorization token:', event.data);
                }
            }
        };
    }, []);

    const { token, userId, accountId } = useAuth();

    const authLink = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
                Authorization: token,
                'va-user-id': userId,
                'va-account-id': accountId,
            },
        };
    });

    const httpLink = createHttpLink({ uri: API_GRAPHQL_URL });

    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
        defaultOptions: {
            query: {
                fetchPolicy: 'no-cache',
            },
            watchQuery: {
                fetchPolicy: 'no-cache',
            },
        },
    });

    const [lsHistoryURL] = useLocalStorageHistoryURL();

    return (
        <Provider store={store}>
            <Router basename="/spa/visual-analytics">
                <ApolloProvider client={client}>
                    <Switch>
                        <Route exact path="/">
                            {lsHistoryURL ? <Redirect to={lsHistoryURL} /> : <AdvertiserPage />}
                        </Route>
                        <Route path="/accounts" exact component={AccountsPage} />
                        <Route path="/accounts/:accountId(\d+)" exact component={AccountPage} />
                        <Route path="/advertisers/:advertiserId(\d+)" exact component={AdvertiserPage} />
                        <Route path="/campaigns/:campaignId(\d+)" exact component={CampaignPage} />
                        <Route component={AdvertiserPage} />
                    </Switch>
                </ApolloProvider>
            </Router>
        </Provider>
    );
}

export default App;
