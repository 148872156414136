import React, { useState, useCallback } from 'react';
import styles from './Grid.module.css';
import { formatNumber } from '../../lib/formatters';
import { Pagination } from '@amzn/storm-ui';

interface GridPaginationProps {
    totalRows: number,
    setCurrentPage: (page: number) => void,
    pageSize: number,
    setPageSize: (size: number) => void,
}

const PAGE_SIZES: number[] = [10, 20, 50, 100];

export const GridPagination: React.FC<GridPaginationProps> = ({totalRows, setCurrentPage, pageSize, setPageSize}) => {

    const [currPage, setCurrPage] = useState<number>(0);

    const changePage = useCallback((page: number) => {
        setCurrentPage(page);
        setCurrPage(page);
    }, [setCurrentPage, setCurrPage]);

    const changePageSize = useCallback((pageSize: number | string) => {
        setPageSize(+pageSize);
    }, [setPageSize]);

    if (totalRows <= 0) {
        return null;
    }

    const pageLabel = paginationLabel(currPage, totalRows, pageSize);
    const totalPages = Math.ceil(totalRows / pageSize);

    if (totalPages === 1) {
        return (
            <div className={styles.gridPagination} id="grid-pagination">
                {pageLabel}
            </div>
        );
    } else {
        return (
            <div className={styles.gridPagination}>
                <Pagination
                    id="grid-pagination"
                    currentPage={currPage}
                    currentSize={pageSize}
                    totalPages={totalPages}
                    pageSizes={PAGE_SIZES}
                    onSetPageSize={changePageSize}
                    setCurrentPage={changePage}
                    currentPageLabel={pageLabel}
                    firstPageLabel="Navigate to first page"
                    prevPageLabel="Navigate to previous page"
                    nextPageLabel="Navigate to next page"
                    lastPageLabel="Navigate to last page"
                    renderPaginationDropdownLabel={(pageSize: number) => `Results per page: ${pageSize}`}
                />
            </div >
        );
    }
};

export function paginationLabel(currPage: number, totalRows: number, pageSize: number): string {
    const skipped = currPage * pageSize;
    const first = skipped + 1;
    const last = Math.min(skipped + pageSize, totalRows);
    return `${formatNumber(first)}-${formatNumber(last)} of ${formatNumber(totalRows)} ${totalRows < 2 ? "result" : "results"}`;
}
