import React, { useCallback } from 'react';
import styles from './FeedbackContent.module.css';
import { defaultSatisfactionLevel, SatisfactionLevel } from './SatisfactionLevel';
import { defaultFeedbackType, FeedbackTypeDropdown } from './FeedbackTypeDropdown';
import { ValidatedTextArea } from './ValidatedTextArea';

export interface Feedback {
    readonly feedbackType: string;
    readonly satisfactionLevel: string;
    readonly feedbackMessage: string;
}

interface FeedbackContentProps {
    readonly errorMessage?: string;
    readonly value: Feedback;
    readonly onChange: (f: Feedback) => void;
}

export const defaultFeedback: Feedback = {
    feedbackMessage: '',
    satisfactionLevel: defaultSatisfactionLevel,
    feedbackType: defaultFeedbackType,
};

export const FeedbackContent: React.FC<FeedbackContentProps> = ({ value, onChange, errorMessage }) => {
    const feedbackTypeChanged = useCallback((feedbackType: string) => onChange({ ...value, feedbackType }), [value, onChange]);
    const feedbackMessageChanged = useCallback((feedbackMessage: string) => onChange({ ...value, feedbackMessage }), [value, onChange]);
    const satisfactionLevelChanged = useCallback((satisfactionLevel: string) => onChange({ ...value, satisfactionLevel }), [value, onChange]);

    return (
        <div className={styles.feedbackContent}>
            <FeedbackTypeDropdown text={value.feedbackType} onChange={feedbackTypeChanged} />
            <ValidatedTextArea text={value.feedbackMessage} onChange={feedbackMessageChanged} errorMessage={errorMessage} />
            <SatisfactionLevel value={value.satisfactionLevel} onChange={satisfactionLevelChanged} />
        </div>
    );
};
