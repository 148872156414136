import gql from "graphql-tag";

const query = gql`
query fetchAdvertiserAndAccountSasMetadata($advertiserId: Long!) {
    advertiser: sasAdvertiserMetadata(advertiserId: $advertiserId) {
        id
        name
        account {
            id
            name
        }
    }
}
`;
export default query;
