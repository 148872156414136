import gql from "graphql-tag";

const query = gql`
query fetchCampaignAndAdvertiserSasMetadata($campaignId: Long!) {
    campaign: sasCampaignMetadata(campaignId: $campaignId) {
        id
        name
        startDate
        endDate
        status
        advertiser {
          id
          name
          account {
              id
              name
          }
        }
    }
}
`;
export default query;
